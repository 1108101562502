

export default function Loader(){
    return (
        <div id="loader" >
        <div id="spinner" ></div>
        <img src={`${process.env.REACT_APP_BACKEND}/nextopson-logo.webp`} />
        <style jsx>{`#loader {
    position: fixed;
    background-color: white;
     top: 0; 
     left: 0; 
     height: 100%;
      width: 100%;
      
      z-index: 40;
       display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

#loader img{
    width: 300px;
}

#spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    z-index: 41;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`}</style>
      </div>
    )
}