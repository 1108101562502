import testImg from "../../assets/DELHI.webp";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import Viewer from "react-viewer";

import solarData from './tempData.json'


import {
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  Input,
  CircularProgress,
  Rating,
} from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";
import PropertyUnit from "./propertyUnit";
import Location from "./location";

import axiosInstance from "../../axiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAreaChart, faBuilding, faDownload, faHouse, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faLinkedin, faXTwitter } from "@fortawesome/free-brands-svg-icons";

function formatCurrency(amount) {
  const num = parseFloat(amount);

  if (isNaN(num)) {
    return "Invalid amount";
  }

  const lakh = 100000;
  const crore = 10000000;

  if (num >= crore) {
    const crores = num / crore;
    // const croresInt = Math.floor(crores);
    // const croresDecimal = crores - croresInt;
    // const lakhs = Math.floor((num % crore) / lakh);

    let formattedAmount = `${crores.toFixed(2)} crore(s)`;
    
    
    return formattedAmount.trim();
  }

  if (num >= lakh) {
    const lakhs = num / lakh;
    const formattedLakhs = lakhs.toFixed(2).replace(/\.?0+$/, '');
    return `${formattedLakhs} lakh`;
  }

  return num.toLocaleString();
}


const isValidPhoneNumber = (phoneNumber) => {
  // Validate if the phone number has exactly 10 digits
  return /^\d{10}$/.test(phoneNumber);
};

const isValidEmail = (email) => {
  // Validate if the email is in a proper format
  return /\S+@\S+\.\S+/.test(email);
};

export default function SolarData() {
  const navigate  = useNavigate()
  const params = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);

  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [contactOpen, setContactOpen] = useState(false);
  const [contactLoading, setContactLoading]= useState(false)
  const [phoneVisible, setPhoneVisible] = useState(false)
  const [phone, setPhone]=useState('')
  const [contactForm, setContactForm] = useState({name:'', phone:'', email:''})

  const handleImageClick = (index) => {
    setActiveIndex(index);
    setVisible(true);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    
    autoplaySpeed: 3000,
    customPaging: (i) => (
      <div
        className={`lg:w-16 lg:h-16 w-8 h-8 overflow-hidden rounded-lg shadow-md mx-2 p-[4px] ${
          i === currentSlide && "bg-heading"
        }`}
      >
        <img
          src={
            process.env.REACT_APP_BACKEND +
            `${projectData.images[i].path}`
          }
          alt={`Thumbnail ${i}`}
          key={`Thumbnail ${i}`}
          className="w-full h-full object-cover rounded-lg"
        />
      </div>
    ),
    beforeChange: (current, next) => setCurrentSlide(next),
    adaptiveHeight: true,
  };

  const [tab, setTab] = useState(0);

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const [projectData, setProjectData] = useState(solarData);

  // async function fetchData() {
  //   try {
  //     const response = await axiosInstance.get("/api/project/get/" + params.id);
  //     setProjectData(response.data);
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //     if (error.response.status === 404) {
  //       navigate("/notfound");
  //     }
  //   }
  // }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const { maxArea, minArea } = useMemo(() => {
    if (
      projectData &&
      projectData.PropertyUnits &&
      projectData.PropertyUnits.length > 0
    ) {
      const newArr = projectData.PropertyUnits.sort((a, b) => a.area - b.area);
      return {
        minArea: newArr[0].area,
        maxArea: newArr[newArr.length - 1].area,
      };
    } else {
      return { minArea: 0, maxArea: 0 };
    }
  }, [projectData]);

  const propertyTypes = useMemo(() => {
    if (
      projectData &&
      projectData.PropertyUnits &&
      projectData.PropertyUnits.length > 0
    ) {
      const temp = projectData.PropertyUnits.map((property) => {
        return property.property_type;
      });
      return [...new Set(temp)];
    } else {
      return "NA";
    }
  }, [projectData]);

  console.log(propertyTypes);

  if (!projectData) {
    return (
      <div className="w-full min-h-screen grid place-items-center">
        Loading...
      </div>
    );
  }

  function handleContactForm(event){
    const name = event.target.name;
    const value = event.target.value;
    setContactForm((prevForm)=>({...prevForm, [name]:value}))
  }

 async function submitContactForm(){
  setPhoneVisible(true)
  setContactLoading(true)
  console.log(contactForm)
  try{

    if(!(contactForm.name && contactForm.phone && contactForm.email) ){
      return 
  }

  if(!isValidPhoneNumber(contactForm.phone)){
    setPhoneVisible(false)
    return window.alert('phone wrong')
  }
  

  if(!isValidEmail(contactForm.email)){
    setPhoneVisible(false)
    return window.alert('email wrong')
  }

  // const response = await axiosInstance.post('/api/view/project', {...contactForm, builder_id:projectData.builder_id, project_id:projectData.project_id})
  // console.log(response)

    setPhone(projectData.phone)
  
  setContactLoading(false)
}catch(error){
  console.log(error)
  setPhoneVisible(false)
}finally{
 
  setContactLoading(false)
}

 
 } 



  return (
    <div>
      <div className="bg-primary capitalize py-10 font-nunito p-2">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 shadow">
            <div className="w-full  lg:col-span-3 bg-primary p-6 rounded-lg">
              <div className="relative">
                <div className="absolute top-2 right-2 flex space-x-2">
                 <Rating value={4.5} precision={0.5}/>
                  
                </div>
                {projectData.images && (
                 <div className="relative   rounded-lg h-[400px] w-full overflow-hidden">
                <Slider {...settings}  id="image-gallery">

                    {projectData.images.map((img, index) => (
                      <div  key={index} style={{backgroundImage:`url(${process.env.REACT_APP_BACKEND}/${projectData.builder_id}/${projectData.project_id}/${img.imageName})`}}>
                        <img
                          className=" w-full object-contain h-[400px]  rounded-lg shadow-md"
                          src={`${process.env.REACT_APP_BACKEND}${img.path}`}
                          alt={`Property Image ${index}`}
                          onClick={()=>handleImageClick(index)}
                        />
                      </div>
                    ))}
                  </Slider>
                 
                  <style jsx>{`
                    .slick-slide{
                      height:400px; 
                    
                      
                    }
                    .slick-dots {
                      white-space: nowrap;

                      width: 100%;
                      overflow-x: auto;
                      margin: 0 0 30px 0;
                    }
                    .slick-dots::-webkit-scrollbar {
                      width: 10px;
                      border-radius: 10px;
                      height: 8px;
                    }

                    /* Track */
                    .slick-dots::-webkit-scrollbar-track {
                      background: #000000;
                      height: 5px;
                    }

                    /* Handle */
                    .slick-dots::-webkit-scrollbar-thumb {
                      background: #88888877;
                      height: 8px;
                      border-radius: 20px;
                      opacity: 0.4;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                      opacity: 0.7;
                      background: #ffffff;
                    }
                    .slick-dots li {
                      width: auto;
                      height: auto;
                      margin: 0;
                    }
                  `}</style>
                  </div>
                )}
                <Viewer
                  visible={visible}
                  onClose={() => setVisible(false)}
                  images={projectData.images.map((img) => ({
                    src:
                      process.env.REACT_APP_BACKEND +
                      `/${projectData.builder_id}/${projectData.project_id}/${img.imageName}`,
                    alt: img.imageName,
                  }))}
                  activeIndex={activeIndex}
                  className=" w-full h-full"
                />
              </div>
              <div className="mt-6">
                <div className="flex  justify-between flex-col gap-4 lg:flex-row">
                <h1 className="text-2xl lg:text-3xl font-bold">{projectData.name}</h1>
                <Button
                    onClick={() => setContactOpen(true)}
                    variant="contained"
                    sx={{ color: "white", height: "60px" , minWidth:'200px' }}
                  >
                    <FontAwesomeIcon className="mr-2 text-xl" icon={faPhone} />{" "}
                    <span className="text-lg">Contact</span>
                  </Button>

                </div>
                {/* <div className="mt-2 flex items-center justify-between">
                  <span className="text-gray-600">
                    by {projectData.builder_name ? projectData.builder_name : projectData.Builder.builder_name}
                  </span>
                </div> */}
                <div className="mt-2 flex items-center justify-between">
                  {projectData.location.landmark},{projectData.location.city}
                  
                  
                </div>
                <div className="mt-4 grid  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faBuilding} className="text-3xl  text-gray-600"/>
                    <div>
                      <span className="block text-sm font-medium text-gray-600">
                        
                      </span>
                      <span className="block text-gray-800">
                        {propertyTypes.join(", ")}
                      </span>
                    </div>
                  </div>
                 
                </div>
                {/* description */}
                <div className="mt-8">
                 
                  <div className="mt-2">
                    <p className="text-gray-600 whitespace-pre-line"><p>Vindhya Solar is a leading Solar EPC (Engineering, Procurement, and Construction) company dedicated to providing reliable and efficient solar power solutions in Madhya Pradesh. As a pioneer in the renewable energy sector, we offer a comprehensive range of services, from planning and design to installation and maintenance, catering to residential, commercial, and industrial clients.<br /><br/>
Our expertise spans solar power systems ranging from 2.16 kW to 10 kW, tailored to meet diverse energy requirements. With a team of skilled professionals and a commitment to excellence, we ensure meticulous execution at every stage, from perfect planning to seamless project management.<br /><br/>
At Vindhya Solar, we prioritize quality, safety, and timely delivery, sourcing only the finest components and adhering to industry best practices. Our technical competence, coupled with a customer-centric approach, guarantees exceptional results and long-lasting performance.<br /><br/>
Embrace the future of sustainable energy with Vindhya Solar, your trusted partner in harnessing the power of the sun for a greener, more efficient Madhya Pradesh.</p></p>
                  </div>

                  {/* description end */}


                  <Location data={projectData.location} />

                  {/* additional details */}
                  <div className="mt-8">
                    <h4 className="text-xl font-bold">Additional Details</h4>
                    <ul className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                      <li className="flex">
                        <span className="w-1/2 md:w-1/3 font-medium text-gray-600">
                          GST no.
                        </span>
                        <span className="w-1/2 md:w-2/3 text-gray-800">
                        23CLQPP5890J1ZC
                        </span>
                      </li>
                     
                      
                    </ul>
                  </div>
                  {/* additional details end */}

                  {/* features   */}
                  
                  {/* features end */}
                </div>

                {/* property types */}
                <div className="w-full  bg-primary mt-8">
                  <h4 className="text-xl font-bold">Our services </h4>
                  <TabContext value={tab}>
                    <Tabs
                      value={tab}
                      onChange={handleTab}
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                      aria-label="scrollable force tabs example"
                    >
                      {projectData.PropertyUnits.map((property, index) => {
                        return (
                          <Tab label={property.property_type} value={index} />
                        );
                      })}
                    </Tabs>
                      
                    {projectData.PropertyUnits.map((property, index) => {
                      return (
                        <TabPanel sx={{padding:0}} value={index}>
                          <PropertyUnit data={property} builder_id={projectData.builder_id} />
                        </TabPanel>
                      );
                    })}
                  </TabContext>
                </div>

                
              </div>
              {/* share  */}
              <div  className="mt-8">
                <h4 className="text-xl font-bold">Share with your friends</h4>
                <div className="mt-4 text-2xl">
                  <ul className="flex space-x-4">
                    <li>
                      <a href="#" title="Share this on Facebook">
                        <FontAwesomeIcon icon={faFacebook}/>
                      </a>
                    </li>

                    <li>
                      <a href="#" title="Share this on Instagram">
                        <FontAwesomeIcon icon={faInstagram}/>
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Share this on Twitter">
                        <FontAwesomeIcon icon={faXTwitter}/>
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Share this on LinkedIn">
                        
                        <FontAwesomeIcon icon={faLinkedin}/>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* share end */}
            </div>
            <div className="w-full   p-0">
              <div className="sticky top-10">
                <aside className="bg-white  rounded-lg shadow-md p-6">
                  <div>
                    <div className="flex items-center gap-2">
                      <div className="w-1/4 ">
                        <a href="">
                          <img
                            src={
                              process.env.REACT_APP_BACKEND +
                              (projectData.builder_logo ? projectData.builder_logo : projectData.Builder.builder_logo)
                            }
                            className="rounded-full"
                            alt="Builder logo"
                          />
                        </a>
                      </div>
                      <div className="w-3/4">
                        <h3 className="text-lg font-bold">
                          {projectData.builder_name ? projectData.builder_name : projectData.Builder.builder_name}
                        </h3>
                      </div>
                    </div>
                    <div className="mt-6">
                      <p className="mt-4 text-gray-600">
                        {projectData.builder_description ? projectData.builder_description : projectData.Builder.builder_description}
                      </p>
                    </div>
                  </div>
                </aside>
                <div className="flex  py-4 gap-2 justify-evenly  lg:flex-col">
                  <Button
                    onClick={() => setContactOpen(true)}
                    variant="contained"
                    sx={{ color: "white", height: "60px" }}
                  >
                    <FontAwesomeIcon className="mr-2 text-xl" icon={faPhone} />{" "}
                    <span className="text-lg">Contact seller</span>
                  </Button>
               
                </div>
                <Dialog
                  open={contactOpen}
                  onClose={() => setContactOpen(false)}
                  maxWidth="lg"
                  
                  className="z-10"
                >
                  <DialogTitle>Contact</DialogTitle>
                  <div className="p-4 grid ">
                    <div className="pb-4">
                      <div className="flex items-center">
                        <div className="w-1/4 ">
                          
                         {projectData.builder_logo ?<a href="">
                            <img
                              src={
                                process.env.REACT_APP_BACKEND+projectData.builder_logo
                              }
                              className="rounded-full bg-white"
                              alt="Builder logo"
                            />
                          </a>
                         
                         
                         :<a href="">
                            <img
                              src={
                                process.env.REACT_APP_BACKEND+ projectData.builder_logo ? projectData.builder_logo : projectData.Builder.builder_logo
                              }
                              className="rounded-full bg-white"
                              alt="Builder logo"
                            />
                          </a>}
                        </div>
                        <div className="w-3/4">
                          <h3 className="text-lg font-bold">
                             {projectData.builder_name ? projectData.builder_name : projectData.Builder.builder_name}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="grid gap-2 relative">
                      {phoneVisible && <div className="absolute w-full h-full bg-white  shadow-lg grid place-items-center  z-10">
                      {contactLoading && <CircularProgress className="z-10  w-full h-full"/>}
                       {phone &&  <div className="p-8  grid gap-2 text-center"><a href={`tel:+91${phone}`} className="text-heading text-lg text-shadow"><FontAwesomeIcon icon={faPhone}/>+91-{phone}</a><h4>you can contact the seller here</h4></div>}
                                
                      </div>}
                      <TextField label='name' value={contactForm.name} name='name' onChange={handleContactForm} placeholder="Name" fullWidth></TextField>
                      <TextField type="number" label='phone' value={contactForm.phone} name="phone" onChange={handleContactForm} placeholder="phone" fullWidth></TextField>
                      <TextField label='email' value={contactForm.email} name="email" onChange={handleContactForm} placeholder="email" fullWidth></TextField>
                      <Button variant="contained" onClick={submitContactForm}>Contact</Button>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
