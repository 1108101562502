import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, Skeleton } from "@mui/material";
import IconButton from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import axiosInstance from "../axiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBathtub,
  faBed,
  faLocation,
  faParking,
  faPhone,
  faX,
} from "@fortawesome/free-solid-svg-icons";

export default function ListingDialog(props) {
  const { onClose, open, propertyId } = props;



  const handleClose = () => {
    onClose();
  };

  const fetchData = async (propertyId) => {
    const response = await axiosInstance(`/api/listing/${propertyId}`);
    return response.data;
  };
  
 
    const { data: details, isLoading } = useQuery({queryKey:['propertyDetails', propertyId],queryFn: () => fetchData(propertyId), enabled:!!propertyId});
  
  
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg">
      <DialogTitle className="">#{propertyId}</DialogTitle>
      <h1
          
          onClick={handleClose}
            className="absolute text-xl top-2 cursor-pointer right-4 "
        ><FontAwesomeIcon icon={faX} /></h1>
         
       
      <div className="pb-8  px-8">
        {details && !isLoading  ? (
          <>
              <h2  className="text-2xl overflow-hidden uppercase text-heading font-semibold mb-2">
                {details.listing_name}
              </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 w-full object-contain gap-4 place-items-center"> 
            <div className="w-2/3">
                <img  src={process.env.REACT_APP_BACKEND+details.thumbnail}></img>
            </div>
              <div className="grid gap-4">
                <p className="mb-2">{details.description}</p>
                <div>
                  <div className="flex items-center mb-2">
                    <FontAwesomeIcon icon={faLocation} className="mr-2" />
                    <p>
                      {details.city}, {details.landmark}
                    </p>
                  </div>
                  <p className="mb-2">{details.address}</p>
                </div>

                <div className="flex items-center mb-2">
                  <p className="mr-2 font-semibold">Price:</p>
                  <p> {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                maximumFractionDigits: "0",
              }).format(details.price)}</p>
                </div>
                <div>
                  <div className="flex items-center mb-2">
                    <FontAwesomeIcon icon={faBed} className="mr-2" />
                    <p>Bedroom: {details.bedroom_count}</p>
                  </div>
                  <div className="flex items-center mb-2">
                    <FontAwesomeIcon icon={faBathtub} className="mr-2" />
                    <p>Bathroom: {details.bathroom_count}</p>
                  </div>
                  <div className="flex items-center mb-2">
                    <FontAwesomeIcon icon={faParking} className="mr-2" />
                    <p>
                      Parking{" "}
                      {details.parking === 1 ? "available" : "not available"}
                    </p>
                  </div>
                </div>
                <div className="flex items-center mb-2">
                  <FontAwesomeIcon icon={faPhone} className="mr-2" />
                  <p>{details.contact}</p>
                </div>
                <div className="flex items-center mb-2">
                  <p className="mr-2 font-semibold">Seller Type:</p>
                  <p>{details.sellerType}</p>
                </div>
              </div>
             

              <div className="bg-gray-200 w-full h-full rounded p-4">
                <h1>{details.comment} </h1>
              </div>
              
            </div>
          </>
        ) : 
                
        <div>
                <Skeleton variant="rectangular" width={400} height={50}></Skeleton>
                    <div className="grid md:grid-cols-2 gap-4 p-2 lg:grid-cols-3">

                <Skeleton variant="rectangular" width={300} height={400}></Skeleton>
                <Skeleton variant="rectangular" width={300} height={400}></Skeleton>
                <Skeleton variant="rectangular" width={300} height={400}></Skeleton>
                </div>
            </div>
        }
      </div>
    </Dialog>
  );
}
