import { Drawer } from "@mui/material";
import {  Slider, Typography ,FormControl, InputLabel,TextField, MenuItem, Select} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import { useQuery } from "@tanstack/react-query";
function valuetext(value) {
    if (value === 100000) {
      return "₹100000+";
    } else {
      return `₹${value}`;
    }
  }
  function valueArea(value) {
    if (value === 100000) {
      return "100000+ sqft";
    } else {
      return `${value} sqft`;
    }
  }
  

export default function FilterDrawer(props){
    const {filters, setFilters, drawerOpen, setDrawer}= props;
    const [searchParams, setSearchParams] = useSearchParams()
    
    const [landmark, setLandmark] = useState('')




  const handleFilterChange = (filter, value) => {
    setSearchParams((prev)=>{ console.log({...prev})
        return {...Object.fromEntries(prev), [filter]: value}});
    searchParams.set("page", filter === "page" ? value : 1);
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: 1,
      [filter]: value,
    }));
    console.log( filter, value)
  };

 

  const handlePriceChange = (event, newValue) => {
    setSearchParams((prev)=>({...Object.fromEntries(prev), 'priceMin':newValue[0], 'priceMax':newValue[1] }))
   
    
    setFilters((prevFilters) => ({
      ...prevFilters,
      priceMin: newValue[0],
      priceMax: newValue[1],
    }));
  };
  const handleAreaChange = (event, newValue) => {

    setSearchParams((prev)=>({...Object.fromEntries(prev), 'areaMin':newValue[0], 'areaMax':newValue[1] }))
    
    setFilters((prevFilters) => ({
      ...prevFilters,
      areaMin: newValue[0],
      areaMax: newValue[1],
    }));
  };



  //                fetch list of cities
  const { data: cityList, isLoading: isCityListLoading } = useQuery({
    queryKey: ['cityList'],
    queryFn:async () => {
     
      const response = await axiosInstance.get('/api/utils/listCity', {
        
        withCredentials:true
      });
      return response.data;
    },
    
      keepPreviousData: true,
      

  });


  //            fetch list of landmarks 

  const { data: landmarkList, isLoading: isLandmarkListLoading } = useQuery({
    queryKey: ['landmarkList',filters.city ],
    queryFn:async () => {
     
      const response = await axiosInstance.get(`/api/utils/listLandmarks/${filters.city}`, {
        
        withCredentials:true
      });
      return response.data;
    },
    
      keepPreviousData: true,
      
    
  });

  const handleLandmark=(event, newValue)=>{
    const value =  landmarkList.find((value)=>{ return value.landmark_name === newValue})
    
    const coordinates =  value?.coordinates;
    if(!value){
      return setLandmark('')
    }
    console.log(coordinates[0], coordinates[1])

    setLandmark(value.landmark_name)

    setSearchParams((prev)=>{
        return {...Object.fromEntries(prev), lat: coordinates[0], long:coordinates[1]}});
    searchParams.set("page", 1);
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: 1,
      
      lat: coordinates[0],
      long:coordinates[1]
    }));


  }



   return( <Drawer
        anchor={window.innerWidth < 720 ? "bottom" : "left"}
        open={drawerOpen}
        onClose={() => setDrawer(false)}
      >
        <div
          className={` inner-div  transition ease-in delay-200 lg:w-[400px] rounded  p-4 `}
        >
          <div className="w-full flex justify-between items-center py-6 px-8 ">
            <h1 className="text-xl font-semibold">Filters</h1>
            <h1
              className="text-heading font-semibold cursor-pointer hover:text-hover"
              onClick={() => {
                setFilters((prevFilters) => {
                  return { sort: prevFilters.sort };
                });
                setSearchParams({ sort: filters.sort });
              }}
            >
              Clear All
            </h1>
          </div>
          <div className="px-8 py-2">
            <TextField
              select
              label="City"
              variant="outlined"
              value={filters.city}
              onChange={(e) => handleFilterChange("city", e.target.value)}
              fullWidth
            >
              {
              // city.city.map((cityName) => (
              //   <MenuItem key={cityName} value={cityName}>
              //     {cityName}
              //   </MenuItem>
              // ))
              
             isCityListLoading ? <MenuItem disabled={true}>Loading</MenuItem> : cityList && cityList.map((city)=>{
                return (
                  <MenuItem key={city.city_name} value={city.city_name}>
                    {city.city_name}
                  </MenuItem>
                )
              })

              
              }
            </TextField>
          </div>
          <div className="px-8 py-2">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Property Type</InputLabel>
              <Select
                value={filters.category}
                onChange={(e) => {
                  
                  handleFilterChange('category',e.target.value);
                }}
                label="Property Type"
              >
                <MenuItem value="" >All</MenuItem>
                <MenuItem value="rent">For Rent</MenuItem>
                <MenuItem value="sale">For Sale</MenuItem>
                {/* <MenuItem value="2">For Rent: Commercial</MenuItem>
                <MenuItem value="3">For Rent: Flatmates</MenuItem>
                <MenuItem value="8">For Sale</MenuItem>
                <MenuItem value="4">For Sale: House & Apartments</MenuItem>
                <MenuItem value="5">For Sale: Commercial</MenuItem>
                <MenuItem value="6">For Sale: Land and Plot</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          {filters.category !== "" && (
                
                <div className="px-8 py-2 font-semibold">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>property type</InputLabel>
                  <Select
                    value={filters.subcategory}
                    onChange={(e) =>
                      handleFilterChange("subcategory", e.target.value)
                    }
                    label="Property type"
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="apartment/house">Apartment / House</MenuItem>
                    <MenuItem value="commercial">Commercial</MenuItem>
                    {filters.category === 'sale' && <MenuItem value="plot/farmhouse">Plot or farmhouse</MenuItem>}
                    {filters.category === 'rent' && <MenuItem value="flatmates">Flatmates</MenuItem>}
                  </Select>
                </FormControl>
              </div>
              )
              }
          <div className=" px-8 py-2 border-b-[1px]">
            <div>
              <Typography id="price-slider" gutterBottom>
                Price Range
              </Typography>
              <Slider
                value={[filters.priceMin, filters.priceMax]}
                onChange={handlePriceChange}
                valueLabelDisplay="auto"
                aria-labelledby="price-slider"
                min={0}
                max={100000}
                getAriaValueText={valuetext}
                valueLabelFormat={valuetext}
              />
              <div
                className="gap-2"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <TextField
                  type="number"
                  label="Min Price"
                  value={filters.priceMin}
                  onChange={(e) =>
                    handleFilterChange("priceMin", e.target.value)
                  }
                />
                <TextField
                  type="number"
                  label="Max Price"
                  value={filters.priceMax}
                  onChange={(e) =>
                    handleFilterChange("priceMax", e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          {filters.city&& landmarkList && (
            <div className="px-8 py-2 ">
              <Autocomplete
                options={landmarkList.map((landmark)=>{return landmark.landmark_name})}
                style={{ textTransform: "capitalize" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ textTransform: "capitalize" }}
                    label="Landmark"
                    variant="outlined"
                  />
                )}
                value={landmark}
                // onChange={(event, newValue) => {
                //   handleFilterChange("landmark", newValue ? newValue : ""); // If newValue is null, set it to an empty string
                // }}
                onChange={handleLandmark}
              />
            </div>
          )}
         {landmark && <div className="px-8 py-2">
            <FormControl fullWidth variant="outlined">
              <InputLabel>search radius</InputLabel>
              <Select
                value={filters.searchRadius}
                onChange={(e) => handleFilterChange("searchRadius", e.target.value)}
                label="search radius"
              >
                <MenuItem value="1000">1 km</MenuItem>
                <MenuItem value="3000">3 km</MenuItem>
                <MenuItem value="5000">5 km</MenuItem>
                <MenuItem value="10000">10 km</MenuItem>
               
                
              </Select>
            </FormControl>
          </div>}
          <div className="px-8 py-2">
            <FormControl fullWidth variant="outlined">
              <InputLabel>BHK Type</InputLabel>
              <Select
                value={filters.bedroom}
                onChange={(e) => handleFilterChange("bedroom", e.target.value)}
                label="BHK Type"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="1">1 BHK</MenuItem>
                <MenuItem value="2">2 BHK</MenuItem>
                <MenuItem value="3">3 BHK</MenuItem>
                <MenuItem value="4">4 BHK</MenuItem>
                <MenuItem value="5">4 BHK+</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div className="px-8 py-2">
              <label className="">
                Bathroom Count:
                <select
                  className="w-max h-8 "
                  value={filters.bathroom}
                  onChange={(e) =>
                    handleFilterChange("bathroom", e.target.value)
                  }
                >
                  <option value="">All</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </label>
            </div> */}
          <div className="px-8 py-2">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Furniture</InputLabel>
              <Select
                value={filters.furniture}
                onChange={(e) =>
                  handleFilterChange("furniture", e.target.value)
                }
                label="Furniture"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="0">Furnished</MenuItem>
                <MenuItem value="1">Semi-Furnished</MenuItem>
                <MenuItem value="2">Unfurnished</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div className="px-8 py-2">
              <label className="">
                Parking:
                <select
                  className="w-max h-8 "
                  value={filters.parking}
                  onChange={(e) =>
                    handleFilterChange("parking", e.target.value)
                  }
                >
                  <option value=''>All</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </label>
            </div> */}
          <div className="px-8 py-2">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Preference</InputLabel>
              <Select
                value={filters.preference}
                onChange={(e) =>
                  handleFilterChange("preference", e.target.value)
                }
                label="Furniture"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="0">Anyone</MenuItem>
                <MenuItem value="1">Boys</MenuItem>
                <MenuItem value="2">Girls</MenuItem>
                <MenuItem value="3">Family</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="px-8 py-2 font-semibold">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Listed By</InputLabel>
              <Select
                value={filters.sellerType}
                onChange={(e) =>
                  handleFilterChange("sellerType", e.target.value)
                }
                label="Listed by"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="owner">Owner</MenuItem>
                <MenuItem value="agent">Agent</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className=" px-8 py-2 border-b-[1px]">
            <div>
              <Typography id="area-slider" gutterBottom>
                Area Range
              </Typography>
              <Slider
                value={[filters.areaMin, filters.areaMax]}
                onChange={handleAreaChange}
                valueLabelDisplay="auto"
                aria-labelledby="area-slider"
                min={0}
                max={100000}
                getAriaValueText={valueArea}
                valueLabelFormat={valueArea}
              />
              <div
                className="gap-2"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <TextField
                  type="number"
                  label="Min Area"
                  value={filters.areaMin}
                  onChange={(e) =>
                    handleFilterChange("areaMin", e.target.value)
                  }
                />
                <TextField
                  type="number"
                  label="Max Area"
                  value={filters.areaMax}
                  onChange={(e) =>
                    handleFilterChange("areaMax", e.target.value)
                  }
                />
              </div>
              <div className=" lg:hidden py-4 w-full grid place-items-center">
                <button
                  className="bg-heading p-4 rounded w-full text-white"
                  onClick={() => {
                    setDrawer(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>

          {/* {filters.category === "sale" && (
                <label>
                  Property type:
                  <select
                    className="w-20 h-8"
                    value={filters.subcategory}
                    onChange={(e) =>
                      handleFilterChange("subcategory", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value="apartment/house">Apartment / House</option>
                    <option value="commercial">Commercial</option>
                    <option value="plot/farmhouse">Plot or farmhouse</option>
                  </select>
                </label>
              )}  */}
        </div>
      </Drawer>)
}