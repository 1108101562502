import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames'; 

export default function ElevatedButton(props) {
  const { label, to,buttonClick, iconImg, className, buttonClassName, overlayClassName, linkClassName, iconClassName, children } = props;

  return (
    <div className={classNames('flex flex-wrap justify-center font-nunito', className)}>
      <Link className={classNames('relative w-full h-full group' , linkClassName)} to={to}>
        <span className={classNames('absolute top-0 left-0 mt-1 group-hover:ml-0 group-hover:mt-0 transition-all ease-in-out duration-500  ml-1 h-full w-full rounded border-1 border-black', overlayClassName)}>
          {/* Overlay styles */}
        </span>
        <span onClick={buttonClick}
          className={classNames(
            ' flex fold-bold relative inline-block h-full w-full justify-center rounded border border-gray-900   bg-white  text-base font-bold text-heading transition duration-100 hover:bg-heading hover:text-white',
            buttonClassName
          )}
        > {iconImg && <img src={iconImg} alt="menu logo" className={classNames("w-6 group-hover:invert mr-2", iconClassName)}></img>}
          {label}
          {children}
        </span>
      </Link>
    </div>
  );
}

ElevatedButton.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string, // Additional class for the outer div
  buttonClassName: PropTypes.string, // Additional class for the button
  overlayClassName: PropTypes.string, // Additional class for the overlay
  linkClassName: PropTypes.string, 
  iconImg:PropTypes.string,// Additional class for the Link component
  iconClassName:PropTypes.string,
  buttonClick:PropTypes.func,
  child:PropTypes.node
};