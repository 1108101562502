import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    QueryClient,
    QueryClientProvider,
    
  } from '@tanstack/react-query'
  import 'slick-carousel/slick/slick.css';
  import 'slick-carousel/slick/slick-theme.css';  
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import './index.css';

import App from './App';

const queryClient = new QueryClient({defaultOptions:{queries:{gcTime:5* 60* 1000, staleTime:3*60*1000 }}})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <QueryClientProvider client={queryClient}>
    <App />
    <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

