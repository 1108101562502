// Response interceptor
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const token= localStorage.getItem('token')

const axiosInstance = axios.create({
    // Your base URL and other configurations
   baseURL:process.env.REACT_APP_BACKEND,
   withCredentials:true,
   
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);







axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // Check if the access token is missing or invalid
       
        
        
          // Redirect to login page if the user is unauthorized token is missing
          
          localStorage.removeItem('id');
          localStorage.removeItem('access_token');
          localStorage.removeItem('tokenExpiration');
          window.location ='/login'; 
          toast.error('login expired', {
            position:toast.POSITION.TOP_RIGHT
          })
          
          
        }else if(error.code === 'ERR_Network'){
          toast.error('No network. Please check your internet connection.', {
            position: toast.POSITION.TOP_RIGHT,
          });

        }
        return Promise.reject(error);
    }
    );

    
    export default axiosInstance;
  