import testImg from "../../assets/DELHI.webp";
import React from "react";
import { useState } from "react";
import Slider from "react-slick";

import { Button} from "@mui/material";


function formatCurrency(amount) {
  const num = parseFloat(amount);

  if (isNaN(num)) {
    return "Invalid amount";
  }

  const lakh = 100000;
  const crore = 10000000;

  if (num >= crore) {
    const crores = num / crore;
    // const croresInt = Math.floor(crores);
    // const croresDecimal = crores - croresInt;
    // const lakhs = Math.floor((num % crore) / lakh);

    let formattedAmount = `${crores.toFixed(2)} crore(s)`;
    
    
    return formattedAmount.trim();
  }

  if (num >= lakh) {
    const lakhs = num / lakh;
    const formattedLakhs = lakhs.toFixed(2).replace(/\.?0+$/, '');
    return `${formattedLakhs} lakh`;
  }

  return num.toLocaleString();
}




export default function PropertyUnit(props){
    console.log(props.data)
    const builder_id = props.builder_id;
    const {project_id,
      description,
       property_type,
        bedroom_count,
        bathroom_count,
        parking,
        area,
        areaMin,
        areaMax,
        floor_number,
        facing_direction,
        floor_map_image,
        balcony_count,
        images:temp_image,
        construction_status,
        construction_year,
        availability_status,
        price,
        additional_features,
        createdAt,
        updatedAt,} = props.data;


    const [currentSlide, setCurrentSlide] = useState(0);
    const [images, setImages]= useState([floor_map_image, ...temp_image]);

   
   
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      customPaging: (i) => (
        <div
          className={`lg:w-16 lg:h-16 w-8 h-8 overflow-hidden rounded-lg shadow-md  p-[4px] ${
            i === currentSlide && "bg-heading"
          }`}
        >
          <img
           
            src={`${process.env.REACT_APP_BACKEND}/${images[i].path}`}
            alt={`Thumbnail ${i}`}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
      ),
      beforeChange: (current, next) => setCurrentSlide(next),
    };
    return (
        <div className=" h-full w-full p-0  shadow-lg bg-primary  ">
           
            <div className="lg:flex gap-4 items-center  rounded-lg">
            
           
            <div className="relative   rounded-lg h-[400px] w-full overflow-hidden">
                <Slider {...settings}  id="image-gallery">

                    {images.map((img, index) => (
                      <div  key={index}>
                        <img
                          className=" w-full object-contain h-[400px]  rounded-lg shadow-md"
                          src={`${process.env.REACT_APP_BACKEND}/${img.path}`}
                          alt={`Property Image ${index}`}
                        />
                      </div>
                    ))}
                  </Slider>
                 
                  <style jsx>{`
                    .slick-slide{
                      height:400px; 
                    
                      
                    }
                    .slick-dots {
                      white-space: nowrap;

                      width: 100%;
                      overflow-x: auto;
                      margin: 0 0 30px 0;
                    }
                    .slick-dots::-webkit-scrollbar {
                      width: 10px;
                      border-radius: 10px;
                      height: 8px;
                    }

                    /* Track */
                    .slick-dots::-webkit-scrollbar-track {
                      background: #000000;
                      height: 5px;
                    }

                    /* Handle */
                    .slick-dots::-webkit-scrollbar-thumb {
                      background: #88888877;
                      height: 8px;
                      border-radius: 20px;
                      opacity: 0.4;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                      opacity: 0.7;
                      background: #ffffff;
                    }
                    .slick-dots li {
                      width: auto;
                      height: auto;
                      margin: 0;
                    }
                  `}</style>
                
              </div>
            </div>

                
             <div className="p-4">
                    {description}
             </div>

              
        </div>
    )
}