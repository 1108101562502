import React from 'react'
import aman from "../../assets/team/aman.jpg"
import yogesh from "../../assets/team/yogesh.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import umesh from "../../assets/team/usmesh.jpg"
import adarsh from "../../assets/team/adarsh.jpg"
import vishnu from "../../assets/team/vishnu.jpg"
import ashish from "../../assets/team/ashish.jpg"
import anurag from "../../assets/team/anurag.jpg"
import vinay from "../../assets/team/vinay.jpg"
import sanket from "../../assets/team/sanket.jpg"


const FoundingTeam = [
    {
        name: 'Anurag Dubey',
        title: 'Founder & CE0',
        image: anurag,
        linkedin: 'https://www.linkedin.com/in/anurag-dubey-2696a918b/',
    },
    {
        name: 'Adarsh Verma',
        title: 'Co-Founder',
        image: adarsh,
        linkedin: 'https://www.linkedin.com/in/adarsh-verma-483841262/',
    },
    {
        name: 'Vinay Shakya',
        title: 'Managing Director',
        image: vinay,
        linkedin: 'https://www.linkedin.com/in/vinay-shakya-5120662a4/',
    },
    {
        name: 'Umesh Shakya',
        title: 'Relationship Manager',
        image: umesh,
        linkedin: 'https://www.linkedin.com/in/umesh-shakya-5a4aa2262/',
    },
    {
        name: 'Vishnu Goswami',
        title: 'Chief Managing Director',
        image: vishnu,
        linkedin: 'https://www.linkedin.com/in/vishnu-goswami-3261302aa/',
    },
    {
        name: 'Ashish Pandey',
        title: 'Team Management',
        image: ashish,
        linkedin: 'https://www.linkedin.com/company/nextopson-in/mycompany/',
    },
];
const TechTeam = [
    {
        name: 'Yogesh Malviya',
        title: 'Tech Head & Senior developer',
        image: yogesh,
        linkedin: 'https://www.linkedin.com/in/yogesh-malviya-ab7928269/',
        github: 'https://github.com/sieg32',
    },
    {
        name: 'Aman Kohare',
        title: 'App developer',
        image: aman,
        linkedin: 'https://www.linkedin.com/in/aman-kohare-3a0678235/',
        github: 'https://github.com/Amankohare0514',
    },
    {
        name: 'Sanket Dhage',
        title: 'Junior developer',
        image: sanket,
        linkedin: 'https://www.linkedin.com/in/sanket-dhage-028a78274/',
        github: 'https://github.com/Sanket-Dhage',
    },
];

const FoundingCard = ({ member }) => {
    return (
        <div className="max-w-sm hover:translate-y-1 rounded-lg overflow-hidden shadow-lg bg-transparent border border-gray-400 p-6 text-center">
            <img className="w-32 h-32 rounded-full mx-auto" src={member.image} alt={`${member.name}`} />
            <div className="pt-6">
                <h2 className="font-bold text-2xl text-gray-200">{member.name}</h2>
                <p className="text-gray-300 tetxt-xl">{member.title}</p>
                <div className="flex justify-center mt-4 space-x-4">
                    <a href={member.linkedin} className="text-gray-200 hover:text-gray-500">
                        <FontAwesomeIcon icon={faLinkedin} size="xl" />
                    </a>
                </div>
            </div>
        </div>
    );
};
const TechCard = ({ member }) => {
    return (
        <div className="max-w-sm hover:translate-y-1 rounded-lg overflow-hidden shadow-lg bg-transparent border border-gray-400 p-6 text-center">
            <img className="w-32 h-32 rounded-full mx-auto" src={member.image} alt={`${member.name}`} />
            <div className="pt-6">
                <h2 className="font-bold text-xl text-gray-200">{member.name}</h2>
                <p className="text-gray-300">{member.title}</p>
                <div className="flex justify-center mt-4 space-x-4">
                    <a href={member.linkedin} className="text-gray-200 hover:text-gray-500">
                        <FontAwesomeIcon icon={faLinkedin} size="lg" />
                    </a>
                    <a href={member.github} className="text-gray-200 hover:text-gray-500">
                        <FontAwesomeIcon icon={faGithub} size="lg" />
                    </a>
                </div>
            </div>
        </div>
    );
};




const Team = () => {
    return (
        <div className="py-12 bg-gradient-to-r font-inter from-blue-950 to-black">
            <div className="container mx-auto px-4">
                <h1 className="text-4xl font-bold  text-center mb-8 text-gray-300">Meet Our Amazing Team</h1>
                <h3 className='text-center font-bold text-heading text-2xl mb-4'>Founding Team</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {FoundingTeam.map((member, index) => (
                        <FoundingCard key={index} member={member} />
                    ))}
                </div>
                <h3 className='text-center mt-8 font-bold text-heading text-2xl mb-4'>Tech Team</h3>
                <div className="grid grid-cols-1 mt-4 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {TechTeam.map((member, index) => (
                        <TechCard key={index} member={member} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Team;
