import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { useEffect, useState } from "react";
import ProjectCard2 from "./projectCard2";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const project = {
  project_id: 11,
  builder_id: 3,
  name: "nigga",
  description: "asdfksaj",
  status: "in construction",
  location: {
    city: "bhopal",
    state: "Madhya Pradesh",
    address: "afdskajf",
    landmark: "bakchodpur",
    coordinates: {
      latitude: "23.254031",
      longitude: "77.445296",
    },
  },
  start_date: "2024-04-12T00:00:00.000Z",
  completion_date: "2024-04-11T00:00:00.000Z",
  total_units: 399,
  price_range: {
    max: "",
    min: "",
  },
  brochure_url: "uploads/3/11/brochure-3-11.pdf",
  images: [
    {
      path: "uploads/3/11/images-3-11-1712514025955.png",
      imageName: "images-3-11-1712514025955.png",
    },
    {
      path: "uploads/3/11/images-3-11-1712514025983.png",
      imageName: "images-3-11-1712514025983.png",
    },
    {
      path: "uploads/3/11/images-3-11-1712514026050.png",
      imageName: "images-3-11-1712514026050.png",
    },
    {
      path: "uploads/3/11/images-3-11-1712514026223.png",
      imageName: "images-3-11-1712514026223.png",
    },
    {
      path: "uploads/3/11/images-3-11-1712514026224.png",
      imageName: "images-3-11-1712514026224.png",
    },
    {
      path: "uploads/3/11/images-3-11-1712514026233.png",
      imageName: "images-3-11-1712514026233.png",
    },
  ],
  rera_id: "askdfja",
  project_property: null,
  createdAt: "2024-04-07T18:20:25.000Z",
  updatedAt: "2024-04-07T18:20:26.000Z",
};

export default function ProjectListing(props) {
 
  const navigate = useNavigate();
  // const builder_id = props.builder_id;
  const queryClient = useQueryClient()
  
  const userInfo = queryClient.getQueryData(['userInfo'])
  

  const builder_id = userInfo?.Builder?.builder_id

  // const [projects, setProjects] = useState([]);

  async function fetchData() {
    
      const response = await axiosInstance.get(
        `/api/search/project?builder_id=${builder_id}`
      );
     return response.data;

   
  }

  const {data:projects , isLoading,  } = useQuery({queryKey:['ProjectsList', builder_id],queryFn:fetchData,enabled:!!builder_id })

 
  return (
    <div>
      <div className="flex justify-end px-2">
        <Button
          variant="contained"
          onClick={() => navigate("/dashboard/addProject")}
        >
          {" "}
          Add Project
        </Button>
      </div>
      <div className="p-4 flex flex-col gap-4">
        
          {
            (projects?.results && !isLoading) && projects.results.map((data)=>{
              return <ProjectCard2 project={data} key={data.project_id}/>
            })
            
          }
          
        
      </div>
    </div>
  );
}
