
// import axiosInstance from "../axiosInstance";
// import Cookies from "js-cookie";
// import { useQueryClient, useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faIdBadge, faPeopleGroup, faUserGroup } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
// import { faUser } from "@fortawesome/free-regular-svg-icons";

// export default function Overview() {

//   const queryClient =  useQueryClient()

//    const userInfo = queryClient.getQueryData(['userInfo'])
//   console.log(userInfo)

//         // recent view ------------------------------
//   const {data:recentViewed, isLoading:isRecentLoading} = useQuery({queryKey:['recentViewed'], queryFn:fetchRecent})

//   async function fetchRecent(){

//     const recentResponse = await axiosInstance.post("/api/view/getRecent", { withCredential: true });
//      return recentResponse.data
//   }



//   // ---------------------------- fetch impressions -----------------------
//   const {data:impressions, isLoading:isImpressionLoading} = useQuery({queryKey:['impressions'], queryFn:fetchImpressions})

//   async function fetchImpressions(){

//     const impressions = await axiosInstance.get("/api/utils/impressions", { withCredential: true });
//      return impressions.data
//   }


//   return (
//     <div className="w-full  min-h-screen  p-8 font-nunito">
//       <div className="grid lg:flex gap-4">
//        {impressions &&  <div className="bg-white rounded-lg shadow-md p-4  max-w-md">
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-2xl font-semibold text-gray-800">Views</h2>

//       </div>
//       <div className="flex justify-between gap-4 items-center m2-6">
//         <div>
//           <h3 className="text-2xl font-bold text-indigo-600">{impressions.impressions}</h3>
//           <p className="text-gray-500">Total Views</p>
//         </div>
//         <div>
//           <h3 className="text-2xl font-bold text-indigo-600">{impressions.impressionsPastMonth}</h3>
//           <p className="text-gray-500">past month</p>
//         </div>
//         <div className="bg-indigo-100 rounded-full p-4">
//           <svg className="w-8 h-8 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
//           </svg>

//         </div>
//       </div>

//     </div>}
//         { impressions &&  <div className="bg-white rounded-lg shadow-md p-4  max-w-md">
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-2xl font-semibold text-gray-800">Leads</h2>

//       </div>
//       <div className="flex justify-between gap-4 items-center m2-6">
//         <div>
//           <h3 className="text-2xl font-bold text-indigo-600">{impressions.leadsCount}</h3>
//           <p className="text-gray-500">Total Leads</p>
//         </div>
//         <div>
//           <h3 className="text-2xl font-bold text-indigo-600">{impressions.leadsPastMonth}</h3>
//           <p className="text-gray-500">past month</p>
//         </div>
//         <div className="bg-indigo-100 rounded-full p-4">
//         <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       className={`feather feather-message-circle w-8 h-8 text-indigo-600`}
//     >
//       <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
//     </svg>
//         </div>
//       </div>

//     </div>}
//       </div>
//       <div className="mt-8 w-full">
//         <div className="flex justify-between">
//         <h1 className="text-xl text-gray-700 font-semibold mb-4">Recent Contacts</h1>
//          <Link to={'/dashboard/recentViews'}>
//           <h2>View All <FontAwesomeIcon icon={faArrowRightLong}/></h2>

//          </Link>
//         </div>
//         <div className="flex w-full  gap-4 p-4 overflow-x-scroll" >
//           {!isRecentLoading && recentViewed.filter((item)=>item.price && item.contact).map((recent, index) => (
//            <div className="min-w-40">
//            <div key={index} className="bg-primary p-4 shadow-xl border-[1px]  rounded grid place-items-center ">
//                <img src={process.env.REACT_APP_BACKEND + recent.thumbnail} className="w-20 h-20 object-contain" alt={recent.listing_name} />
//               <a href={`/property/${recent.listing_id}`}>
//                 <h1 className="text-lg font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[8rem]">{recent.listing_name}</h1>
//                 </a>
//               <p className="text-sm">{new Intl.NumberFormat('en-IN', { style: "currency", currency: "INR", maximumFractionDigits: '0' }).format(recent.price)}</p>
//             </div>
//             <a href={`tel:${recent.contact}`} className="bg-green-500 rounded-b text-white p-2 grid  place-items-center">
//               <h1>call </h1>
//               <p className="text-sm">+91-{recent.contact}</p>
//             </a>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }


import axiosInstance from "../axiosInstance";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";

export default function Overview() {
  const queryClient = useQueryClient();

  const userInfo = queryClient.getQueryData(['userInfo']);
  console.log(userInfo);

  // Fetch recent views
  const { data: recentViewed, isLoading: isRecentLoading } = useQuery({
    queryKey: ['recentViewed'],
    queryFn: fetchRecent,
  });

  async function fetchRecent() {
    const recentResponse = await axiosInstance.post("/api/view/getRecent", { withCredential: true });
    return recentResponse.data;
  }

  // Fetch impressions
  const { data: impressions, isLoading: isImpressionLoading } = useQuery({
    queryKey: ['impressions'],
    queryFn: fetchImpressions,
  });

  async function fetchImpressions() {
    const impressions = await axiosInstance.get("/api/utils/impressions", { withCredential: true });
    return impressions.data;
  }

  if (isRecentLoading || isImpressionLoading) {
    return <div className="min-h-screen grid items-center w-full">Loading...</div>;
  }

  
  const transformImpression =  ()=>{
    console.log(impressions.impressionCountsPerMonth, impressions.leadCountsPerMonth)
    if(!impressions.impressionCountsPerMonth || impressions.impressionCountsPerMonth.length === 0){
      return {totalViews:0, lastMonthView:0}
    }
    
    let tempImpression = 0;
  
    impressions.impressionCountsPerMonth.forEach(element => {
      tempImpression += element.impressionCount;
    });
    

    
    
    const lastMonthView = impressions.impressionCountsPerMonth.at(-1).impressionCount;
    
    
    return {totalViews:tempImpression, lastMonthView:lastMonthView }
    
  }
  const transformLead =()=>{
    if(!impressions.leadCountsPerMonth || impressions.leadCountsPerMonth.length === 0){
      
     return {totalLeads:0, lastMonthLead:0}
    }

    
    let tempLead = 0;
    impressions.leadCountsPerMonth.forEach(element=>{
      tempLead += element.leadCount;
    })
    
    const lastMonthLead = impressions.leadCountsPerMonth.at(-1).leadCount;
    return {totalLeads:tempLead,lastMonthLead:lastMonthLead}
  }

  const { totalLeads,  lastMonthLead} = transformLead()
  const {totalViews,  lastMonthView}= transformImpression()
  
  
  return (
    <div >
    <div className="flex flex-col min-h-screen">
      <header className="text-black py-4 px-4 md:px-6">
        <h1 className="text-2xl font-bold">Analytics Overview</h1>
      </header>
      <main className="flex-1  p-4 md:p-4 grid gap-6">
        {impressions && <div className="grid grid-cols-1 gap-6 md:grid-cols-2 ">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="mb-4">
              <h2 className="text-xl font-semibold">Views</h2>
              <p className="text-gray-500">Visualize your properties page views.</p>
            </div>
            <div className="mb-4">
             {impressions && impressions.impressionCountsPerMonth.length > 0 && <LineChart className="aspect-[9/4]" data={impressions.impressionCountsPerMonth}/>}
            </div>
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              {/* <div className="text-center">
                <div className="text-2xl font-bold">{impressions.impressions}</div>
                <div className="text-gray-500">Total Views</div>
              </div> */}
              <div className="text-center">
                <div className="text-2xl font-bold">{lastMonthView}</div>
                <div className="text-gray-500">Past month</div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="mb-4">
              <h2 className="text-xl font-semibold">Leads</h2>
              <p className="text-gray-500">Track your lead generation and conversion rates.</p>
            </div>
            <div className="mb-4">
              {impressions && impressions.leadCountsPerMonth.length>0 && <BarChart className="aspect-[9/4]" data={impressions.leadCountsPerMonth} />}
            </div>
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              {/* <div className="text-center">
                <div className="text-2xl font-bold">{impressions.leadsCount}</div>
                <div className="text-gray-500">Total Leads</div>
              </div> */}
              <div className="text-center">
                <div className="text-2xl font-bold">{lastMonthLead}</div>
                <div className="text-gray-500">Past month</div>
              </div>
            </div>
          </div>
        </div>}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <div className="mb-4">
            <h2 className="text-xl font-semibold">Analytics</h2>
            <p className="text-gray-500">Key metrics and insights for your properties.</p>
          </div>
          <div className="grid grid-cols-3 gap-6 h-min md:grid-cols-3">
            <div className="flex flex-col items-center justify-start space-y-2">
              <div className="text-3xl font-bold">{totalViews}</div>
              <div className="text-gray-500">Total Views</div>
            </div>
            <div className="flex flex-col items-center justify-start space-y-2">
              <div className="text-3xl font-bold">{totalLeads}</div>
              <div className="text-gray-500">Total Leads</div>
            </div>
            <div className="flex flex-col items-center justify-start space-y-2">
              <div className="text-3xl font-bold">{totalViews===0 ? 0 :((totalLeads / totalViews) * 100).toFixed(2)}%</div>
              <div className="text-center text-gray-500">Conversion Rate</div>
            </div>
          </div>
        </div>
      </main>
    </div>
      <div className="mt-8 w-full p-8">
        <div className="flex justify-between">
          <h1 className="text-xl text-gray-700 font-semibold mb-4">Recent Contacts</h1>
          <Link to={'/dashboard/recentViews'}>
            <h2>View All <FontAwesomeIcon icon={faArrowRightLong} /></h2>

          </Link>
        </div>
        <div className="flex w-full  gap-4 p-4 overflow-x-scroll" >
          {!isRecentLoading && recentViewed.filter((item) => item.price && item.contact).map((recent, index) => (
            <div className="min-w-40">
              <div key={index} className="bg-primary p-4 shadow-xl border-[1px]  rounded grid place-items-center ">
                <img src={process.env.REACT_APP_BACKEND + recent.thumbnail} className="w-20 h-20 object-contain" alt={recent.listing_name} />
                <a href={`/property/${recent.listing_id}`}>
                  <h1 className="text-lg font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[8rem]">{recent.listing_name}</h1>
                </a>
                <p className="text-sm">{new Intl.NumberFormat('en-IN', { style: "currency", currency: "INR", maximumFractionDigits: '0' }).format(recent.price)}</p>
              </div>
              <a href={`tel:${recent.contact}`} className="bg-heading rounded-b text-white p-2 grid  place-items-center">
                <h1>call </h1>
                <p className="text-sm">+91-{recent.contact}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
      </div>

  );
}

function BarChart(props) {
  const {data} = props;
  const newData = data.map((point)=>{
    return {month:point.month, leads:point.leadCount}
  })

 
  return (
    <div {...props}>
      <ResponsiveBar
        data={newData}
        keys={["leads"]}
        indexBy="month"
        margin={{ top: 0, right: 0, bottom: 40, left: 40 }}
        padding={0.3}
        colors={["#2563eb"]}
        axisBottom={{
          tickSize: 0,
          tickPadding: 16,
        }}
        axisLeft={{
          tickSize: 0,
          tickValues: 4,
          tickPadding: 16,
        }}
        gridYValues={4}
        theme={{
          tooltip: {
            chip: {
              borderRadius: "9999px",
            },
            container: {
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "6px",
            },
          },
          grid: {
            line: {
              stroke: "#f3f4f6",
            },
          },
        }}
        tooltipLabel={({ id }) => `${id}`}
        enableLabel={false}
        role="application"
        ariaLabel="A bar chart showing data"
      />
    </div>
  );
}

function LineChart(props) {
  const {data} = props;
  const newData = data.map((point)=>{
    return {y:point.impressionCount, x:point.month}
  })
  return (
    <div {...props}>
      <ResponsiveLine
        data={[
          {
            id: "Desktop",
            data:newData,
          }
        ]}
        margin={{ top: 10, right: 10, bottom: 40, left: 40 }}
        xScale={{
          type: "point",
        }}
        yScale={{
          type: "linear",
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 16,
        }}
        axisLeft={{
          tickSize: 0,
          tickValues: 5,
          tickPadding: 16,
        }}
        colors={["#2563eb", "#e11d48"]}
        pointSize={6}
        useMesh={true}
        gridYValues={6}
        theme={{
          tooltip: {
            chip: {
              borderRadius: "9999px",
            },
            container: {
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "6px",
            },
          },
          grid: {
            line: {
              stroke: "#f3f4f6",
            },
          },
        }}
        role="application"
      />
    </div>
  );
}
