import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../axiosInstance";
import ProjectCard from "../project/projectCard";
import { Badge, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import ScreenRotationAltOutlinedIcon from '@mui/icons-material/ScreenRotationAltOutlined';
import CompareDialog from "./compareDialog";
import Loader from "./Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceGrinBeamSweat } from "@fortawesome/free-solid-svg-icons";
import cityData from "../../city.json"; 

export default function HotDealsPage() {
  //all set states
  const [tempQuery, setTempQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [priceDialogOpen, setPriceDialogOpen] = useState(false);
  const [compareList, setCompareList] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 10000000]); 
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({ hotDeals:'true',query: '', city: '', propertyType: '', landmark: '', page:currentPage || 1 });
  const [landmarks, setLandmarks] = useState([]);
  const projectsPerPage = 30;

  //for city filter
  function handleFilter(e) {
    setFilter((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }

  //input field filter
  function handleTempQuery(e) {
    if (e.key === "Enter") {
      setFilter((prev) => {
        return { ...prev, query: tempQuery };
      });
    }
  }

  // data fetching
  async function fetchData() {
    const params = { ...filter, page: currentPage, limit: projectsPerPage };
    if (filter.propertyType === "All") {
      delete params.propertyType;
    }
    const { data } = await axiosInstance.get(`/api/search/project/elastic`, { params });
    return data;
  }

  const { data, isLoading, isError } = useQuery({
    queryKey: ["hotDealsPage", filter, currentPage],
    queryFn: fetchData,
  });

  //landmark select according to city
  useEffect(() => {
    if (filter.city) {
      setLandmarks(cityData[filter.city] || []);
      setFilter(prevFilter => ({ ...prevFilter, landmark: '' }));
    }
  }, [filter.city]);


  useEffect(()=>{
    setFilter(prev=>({...prev, page:1}))
  },[filter])

  //range filter
  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  //all filter
  const handleClearFilters = () => {
    setTempQuery("");
    setFilter({ query: '', city: '', propertyType: '', landmark: '' });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  //                fetch list of cities
  const { data: cityList, isLoading: isCityListLoading } = useQuery({
    queryKey: ["cityList"],
    queryFn: async () => {
      const response = await axiosInstance.get("/api/utils/listCity", {
        withCredentials: true,
      });
      return response.data;
    },

    keepPreviousData: true,
  });

  //            fetch list of landmarks

  const { data: landmarkList, isLoading: isLandmarkListLoading } = useQuery({
    queryKey: ["landmarkList", filter.city],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/api/utils/listLandmarks/${filter.city}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    },

    enabled: !!filter.city,
  });

  //main styling start
  return (
    <div
      className="font-inter h-full bg-gradient-to-r from-blue-950 to-black border-b border-gray-600"
    >
      <CompareDialog open={open} setOpen={setOpen} compareList={compareList} setCompareList={setCompareList} />

      <div
        className="w-full grid place-items-center gap-8 px-4 py-8"
        style={{
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      >
        <div className="text-primary font-nunito capitalize mt-8">
          <h3 className="text-3xl lg:text-6xl py-2" style={{ textShadow: "0 0 10px black" }}>
            <span className="text-heading">Discover</span> A Place <br /> Where You Love to Live
          </h3>
          <p className="text-2xl mt-2" style={{ textShadow: "0 0 10px black" }}>
            Unlock Exclusive Deals, Handpicked for You
          </p>
        </div>

        <div className="w-full mt-4 bg-transparent border border-gray-600 text-white max-w-[1200px] mx-auto p-2 px-4 rounded-md md:rounded-2xl lg:rounded-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:flex lg:flex gap-4">
            <div className="w-full relative flex items-center col-span-2">

              {/* input field  */}
              <input
                type="text"
                className="text-xl border-gray-700 border-r-2 flex-grow text-gray-400 bg-transparent py-3 px-4 text-start focus:outline-none focus:border-transparent"
                value={tempQuery}
                placeholder="Search hot deals"
                onKeyDown={handleTempQuery}
                onChange={(e) => setTempQuery(e.target.value)}
              />

            </div>

            {/* select for location */}
            <div className="w-full sm:w-auto">
              {cityList && <select
                className="w-full border sm:w-[130px] rounded-full border-gray-900 bg-transparent text-gray-400 px-4 py-4 focus:outline-none focus:border-transparent"
                name="city"
                onChange={handleFilter}
                value={filter.city}
                aria-label="Location"
                >
                <option value="" disabled>Location</option>
                {cityList.map((city)=>{
                  return <option className="capitalize" value={city.city_name}>{city.city_name}</option>
                })}
                
              </select>}
            </div>

            {/* select for landmark */}
            <div className="w-full sm:w-auto">
              {filter.city && landmarkList && <select
                className="w-full border sm:w-[160px] rounded-full border-gray-900 bg-transparent text-gray-400 px-4 py-4 focus:outline-none focus:border-transparent"
                name="landmark"
                onChange={handleFilter}
                value={filter.landmark}
                aria-label="Landmark"
                disabled={!filter.city}
              >
                <option value="" >All landmarks</option>
                {landmarkList.map((landmark) => (
                  <option key={landmark.landmark_id} value={landmark.landmark_name}>{landmark.landmark_name}</option>
                ))}
              </select>}
            </div>

            {/* select for property type */}
            <div className="w-full sm:w-auto">
              <select
                className="w-full border sm:w-[120px] rounded-full border-gray-900 bg-transparent text-gray-400 px-4 py-4 focus:outline-none focus:border-transparent"
                name="propertyType"
                onChange={handleFilter}
                value={filter.propertyType}
                aria-label="Property Type"
              >
                <option value="all" disabled selected>Type</option>
                <option value="">All</option>
                <option value="1RK">1RK</option>
                <option value="1BHK">1BHK</option>
                <option value="2BHK">2BHK</option>
                <option value="3BHK">3BHK</option>
                <option value="4BHK">4BHK</option>
                <option value="5BHK">5BHK</option>
                <option value="singlex">Singlex</option>
                <option value="duplex">Duplex</option>
                <option value="triplex">Triplex</option>
                <option value="villa">Villa</option>
                <option value="plots">Plots</option>
                <option value="farmhouse">Farmhouse</option>
                <option value="commercial">Commercial</option>
              </select>
            </div>

            {/* button for clear filter */}
            <div className="w-full sm:w-auto flex justify-end items-center col-span-2 sm:col-span-1">
              <button
                onClick={handleClearFilters}
                className="w-full border sm:w-[140px] rounded-full border-gray-700 bg-transparent text-gray-400 py-4">
                Clear Filters
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4">
        {/* if there is no data loading then show loader */}
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <Loader />
          </div>
        ) : (
          <>
            <div className="flex justify-between px-4">
              <h1 className="text-3xl font-semibold text-gray-200 lg:ml-12">Hot Deals</h1>
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                color="secondary"
                badgeContent={compareList.length}
              >
                <button
                  onClick={() => {
                    compareList.length > 0 && setOpen(true);
                  }}
                  variant="text"
                  className="text-gray-300 border px-2 py-2 rounded-md hover:bg-gray-200 hover:text-black"
                >
                  Compare{" "}
                  <ScreenRotationAltOutlinedIcon fontSize="18" />
                </button>
              </Badge>
            </div>
            <div className="p-4 grid grid-cols-1 gap-4 place-items-center md:grid-cols-2 lg:grid-cols-4">
              {data && data.count > 0 ? data.results.map((project) => (
                <div key={project.slug} className="w-fit">
                  <ProjectCard
                    project={project}
                    compared={compareList.includes(project.slug)}
                    compareList={compareList}
                    setCompareList={setCompareList}
                  />
                </div>
              )) : (
                <div className="text-primary grid h-3/4 min-h-[400px] place-items-center">
                  <div className="flex items-center">
                    <FontAwesomeIcon className="text-6xl" icon={faFaceGrinBeamSweat} />
                    <div className="p-4">
                      <h1 className="text-3xl">No Result Found</h1>
                      <h4 className="text-2xl">Sorry we could not find any matching result</h4>
                      <h4>Try different keyword and Filters</h4>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {data && data.count > projectsPerPage && (
              <div className="flex justify-center mt-4">
                <Pagination
                  count={Math.ceil(data.count / projectsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{
                    button: { color: 'white' },
                    '.MuiPaginationItem-root': { color: 'white' },
                    '.Mui-selected': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                    '.MuiPaginationItem-page.Mui-selected': { color: 'white' }
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
