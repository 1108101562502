import React from 'react';

const PrivacyPage = () => {
  return (
    <div className='bg-gradient-to-r font-inter from-blue-950 to-black'>
      <main className="max-w-7xl  font-inter mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-20">
        <div className="space-y-6">
          <h1 className="text-3xl font-bold tracking-tight text-gray-200 ">Privacy Policy</h1>
          <p className=" text-gray-300 text-sm">
            At NextOpson.in, we are committed to protecting the privacy and security of your personal information. This
            privacy policy explains how we collect, use, and safeguard your data.
          </p>
          <section>
            <h2 className="text-2xl font-bold tracking-tight text-gray-200 ">
              What information do we collect?
            </h2>

            <p className="list-disc mt-4  text-gray-300 text-sm">
              NextOpson is a real estate information service. To provide our Services, we use the information we collect to:
              Help you find the most relevant information for your situation by customizing our Services to optimize your experience;
              Keep you connected with NextOpson across the internet and update you with residential real estate-related news and information; and
              Put you in touch with the right people to sell, buy, rent, or research residential real estate, obtain a mortgage, and to optimize the information we share with those parties to initiate a productive and efficient relationship.
            </p>
            <p className="text-gray-300 mt-2 text-sm">
              We use this information to provide and improve our services, to communicate with you, and to comply with
              legal and regulatory requirements.
            </p>
          </section>
          <section>
            <h2 className="text-2xl font-bold tracking-tight text-gray-200 ">
              How do we store and secure your information?
            </h2>
            <p className="text-gray-300 mt-4 text-sm">
              We Collect Some Information From All Visitors to Our Website: Like many online services, we use technologies like session and persistent cookies, web beacons (tiny image files on web pages that communicate information about the page viewer to the beacon owner), log data, and third-party analytics services to collect and analyze information about all users of our Services This includes things like User search preferences, interaction with ads on our Webite, and location.

              Our servers automatically record information ("Log Data") created by your use of the Services. Log Data may include information such as your IP address, browser type, operating system, the referring web page, pages visited, location, your mobile number, your mobile carrier, your email address, device, search terms, and cookie information. We receive Log Data when you interact with our Services, for example, when you visit our websites, sign into our Services, or interact with our email notifications. <br /><br />We use Log Data to provide our Services and to measure, customize, and improve them, as well as collect and use them in the aggregate. This Policy does not apply to, nor do we take any responsibility for any information that is collected by any third party either using the Website or through any links on the Website or through any of the advertisements.

              We collect some personal information like name, contact details, mobile number, email address, profile images/photos/pictures which you have provided us during the account creation / registration process or while interacting with any of our services to ensure a smoother user experience and to tailor our services to your preferences and interests. <br /> <br />

              Cookies are small data files that we transfer to your computer. We use "session" cookies to keep you logged in while you use our Services, to better understand how you interact with our Services, and to monitor aggregate usage and web traffic information on our Services. We use "persistent" cookies to recognize you each time you return to our Services. For example, we create a persistent cookie that includes some basic information about you, like your most recent search and whether an agent responded to a query you sent using NextOpson.in's Services.<br /> We use this persistent cookie to remember your preferences and, if you create an account, to make your user experience consistent after you register.

              Most internet browsers automatically accept cookies, but you can change your settings or use third-party tools to refuse cookies or prompt you before accepting cookies from the websites you visit. ,<br /> <br />You can also use your browser settings or other tools to delete cookies you already have. Please be aware that some parts of our Services may not work for you if you disable cookies.

              Information We Collect from REPs: If you create a REP account with us, whether as a landlord, agent, developer or subscriber we will ask you to provide certain information in order to set up your account. We ask all REPs to provide a name, a company (if available), a phone number, and an email address. If you create a paid REP account and become a subscriber, we require you to submit all of the information required from landlords and agents as well as a description of your campaign. We will also collect financial data like credit card information and billing addresses from subscribers, so that we can handle billing. If you are a subscriber, a NextOpson.in representative may call you to verify your information.<br />

              Additional Information About You that We Obtain from Third Parties: We may sometimes obtain information about you from third parties and use it to help agents serve you better. For example, our partners may help us provide predictions of your income range and how likely you may be to purchase a home. <br /> <br />We will share this augmented data only with selected REPs (subscribers) who you choose to share information with by contacting them through the NextOpson.in Services.

              Location Data: If you provide location information (like a ZIP code, street address, or even just a city) during the registration process or at any other time via your account settings, we will store that information and associate it with your account.
            </p>
          </section>
          <section>
            <h2 className="text-2xl font-bold tracking-tight text-gray-200 ">
              How can you access, update, or delete your information?
            </h2>
            <p className="text-gray-300 text-sm mt-4">
              You have the right to access, update, and delete your personal information at any time. To do so, please
              contact our customer support team at thenextopson@gmail.com. We will respond to your request within 2 days.
            </p>
          </section>
          <section>
            <h2 className="text-2xl font-bold tracking-tight text-gray-200 ">Changes to this policy</h2>
            <p className="text-gray-300 text-sm mt-4">
              We may update this privacy policy from time to time to reflect changes in our practices or applicable laws.
              We will notify you of any material changes by posting the updated policy on our website and, if appropriate,
              by email.
            </p>
          </section>
          <section>
            <h2 className="text-2xl font-bold tracking-tight text-gray-200 ">Contact us</h2>
            <p className="text-gray-300 text-sm mt-4">
              If you have any questions or concerns about this privacy policy or our data practices, please contact us at
              thenextopson@gmail.com
            </p>
          </section>
        </div>
      </main>
    </div>
  );
};

export default PrivacyPage;
