import {  Drawer, IconButton } from "@mui/material";
import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axiosInstance from "../../axiosInstance";
import { useQuery } from "@tanstack/react-query";
import CompareMap from "./compareMap";
import SimpleBackdrop from '../SimpleBackdrop'

//icons
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import CloseIcon from '@mui/icons-material/Close';
function formatCurrency(amount) {
  const num = parseFloat(amount);

  if (isNaN(num)) {
    return "Invalid amount";
  }

  const lakh = 100000;
  const crore = 10000000;

  if (num >= crore) {
    const crores = num / crore;
    // const croresInt = Math.floor(crores);
    // const croresDecimal = crores - croresInt;
    // const lakhs = Math.floor((num % crore) / lakh);

    let formattedAmount = `${crores.toFixed(2)} crore`;


    return formattedAmount.trim();
  }

  if (num >= lakh) {
    const lakhs = num / lakh;
    const formattedLakhs = lakhs.toFixed(2).replace(/\.?0+$/, '');
    return `${formattedLakhs} lakh`;
  }

  return num.toLocaleString();
}




const projects = [
  {
    name: "Project A",
    location: { city: "New York" },
    total_units: 100,
    price_range: { min: 500000, max: 1000000 },
    start_date: "2023-01-01",
    completion_date: "2024-12-31",
  },
  {
    name: "Project B",
    location: { city: "Los Angeles" },
    total_units: 50,
    price_range: { min: 800000, max: 1500000 },
    start_date: "2022-06-01",
    completion_date: "2024-03-31",
  },
  // Add more project objects here
];

export default function CompareDialog({ open, setOpen, compareList }) {
  const projectKeys = [
    "name",
    "location.city",
    "total_units",
    "price_range.min",
    "price_range.max",
    "start_date",
    "completion_date",
  ];

  function handleClose() {
    setOpen(false);
  }

  const length = compareList.length ? compareList.length : 0;
  console.log(compareList);

  async function fetchProjects() {
    if (compareList && compareList.length === 0) {
      return null;
    }

    const responsePromise = compareList.map((value) => {
      return axiosInstance.get(`/api/project/get/${value}`);
    });

    const results = await Promise.all(responsePromise);
    console.log(results);
    console.log("fetch called");
    return results.map((value) => value.data);
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ["compareProjectList", compareList],
    queryFn: fetchProjects,
    enabled: (compareList.length > 0 && open),
  });

  function getPropertyTypes(projectData) {
    if (
      projectData &&
      projectData.PropertyUnits &&
      projectData.PropertyUnits.length > 0
    ) {
      const temp = projectData.PropertyUnits.map((property) => {
        return property.property_type;
      });
      return [...new Set(temp)];
    } else {
      return "NA";
    }
  }

  const temp = [...Array(length)];
  const keys = {
    builder: ["builder"],
    address: ["location", "address"],
    Units: ["total_units"],
    Price: ["price_range", "max"],
    status: ["status"],
  };

  function extractCoordinates() {
    return data.map((project) => {
      return { name: project.name, location: project.location.coordinates };
    })
  }


  console.log(data, isLoading);
  return (
    <>
      <Drawer maxWidth open={open} onClose={handleClose} anchor={window.innerWidth < 720 ? 'bottom' : 'left'}  >
        {isLoading && <SimpleBackdrop />}
        <div className="flex justify-between items-center px-4 py-2 bg-gray-50  border-gray-300">
          <div>
            <h1 className="text-3xl text-black bg-gray-200 px-2 py-1 rounded-lg">
              <CompareArrowsRoundedIcon fontSize="42" /> Compare
            </h1>
          </div>
          <IconButton onClick={handleClose}>
          <CloseIcon />
          </IconButton>
        </div>
        <div className="h-full relative px-4 sm:px-6 lg:px-8">
          <div className="flex h-full border border-gray-300">
            {data && <div className="hidden border-r-1 lg:block min-w-[600px] h-full">
              <CompareMap coordinates={extractCoordinates()} />
            </div>}
            <div className="overflow-auto">
              {data && (
                <Table stickyHeader>
                  <TableHead>
                    <TableCell>Project</TableCell>
                    {data &&
                      temp.map((_, index) => {
                        return (
                          <TableCell key={index}>
                            <div className="flex flex-col items-center">
                              <img
                                className="rounded-lg object-cover w-16 h-16 sm:w-24 sm:h-24"
                                src={`${process.env.REACT_APP_BACKEND}/${data[index].builder_id}/${data[index].project_id}/${data[index].images[0].imageName}`}
                                alt={data[index].name}
                              />
                              <span className="text-xs sm:text-sm">
                                {data[index].name}
                              </span>
                            </div>
                          </TableCell>
                        );
                      })}
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Builder</TableCell>
                      {data &&
                        temp.map((_, index) => {
                          return (
                            <TableCell>
                              <div className="max-w-[100px] overflow-hidden cover">
                                {data[index].builder_name ? data[index].builder_name : data[index].Builder.builder_name}
                              </div>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      {data &&
                        temp.map((_, index) => {
                          return (
                            <TableCell>
                              <div className="max-w-[100px] overflow-hidden cover">
                                {getPropertyTypes(data[index]).join(",")}
                              </div>
                            </TableCell>
                          );
                        })}
                    </TableRow>


                    <TableRow>
                      <TableCell>Unit</TableCell>
                      {data &&
                        temp.map((_, index) => {
                          return (
                            <TableCell>
                              <div className="max-w-[100px] overflow-hidden cover">
                                {data[index].total_units}
                              </div>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                    <TableRow>
                      <TableCell>Budget</TableCell>
                      {data &&
                        temp.map((_, index) => {
                          return (
                            <TableCell>
                              <div className="max-w-[100px] overflow-hidden cover">
                                {formatCurrency(data[index].price_range.min) + '-' + formatCurrency(data[index].price_range.max)}
                              </div>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      {data &&
                        temp.map((_, index) => {
                          return (
                            <TableCell>
                              <div className="max-w-[100px] overflow-hidden cover">
                                {data[index].status}
                              </div>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                    <TableRow>
                      <TableCell>Area</TableCell>
                      {data &&
                        temp.map((_, index) => {
                          return (
                            <TableCell>
                              <div className="max-w-[100px] text-start overflow-hidden cover">
                                <ul>

                                  {data[index].PropertyUnits.map((unit) => {
                                    return (
                                      <li>{unit.property_type}-{unit.area ? unit.area : `${unit.areaMin}-${unit.areaMax}`} sqft</li>
                                    )
                                  })}
                                </ul>
                              </div>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                    <TableRow>
                      <TableCell>Pricing</TableCell>
                      {data &&
                        temp.map((_, index) => {
                          return (
                            <TableCell>
                              <div className="max-w-[100px] overflow-hidden cover">
                                <ul>

                                  {data[index].PropertyUnits.map((unit) => {
                                    return (
                                      <li>{unit.property_type}-{unit.property_type === 'plots' ? `${unit.price} per sqft` : formatCurrency(unit.price)}</li>
                                    )
                                  })}
                                </ul>
                              </div>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                    <TableRow>
                      <TableCell>Completion</TableCell>
                      {data &&
                        temp.map((_, index) => {
                          return (
                            <TableCell>
                              <div className="max-w-[100px] overflow-hidden cover">
                                {data[index].completion_date && new Date(data[index].completion_date).toLocaleDateString()}
                              </div>
                            </TableCell>
                          );
                        })}
                    </TableRow>

                  </TableBody>
                </Table>
              )}
            </div>
          </div>
        </div>

      </Drawer>
    </>
  );
}
