// PropertyCard.jsx
import React, { useState } from "react";
import axiosInstance from "../axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import AlertDialog from "./alertDialog"; 
import SimpleBackdrop from "./SimpleBackdrop"; 
import LoadingButton from "@mui/lab/LoadingButton";
import { saveAs } from "file-saver";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import { MenuItem, menuItemClasses } from "@mui/base/MenuItem";
import ElevatedButton from "./elevatedButton";
import Pricing from "./pages/Pricing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faIndianRupeeSign, faRupeeSign } from "@fortawesome/free-solid-svg-icons";


const PropertyCard = (props) => {
  const navigate = useNavigate();
  const {
    bathroom_count,
    bedroom_count,
    category,
    city,
    description,
    furniture,
    landmark,
    listing_id,
    listing_name,
    parking,
    price,
    subcategory,
    thumbnail,
    createdAt,
    isPublic
  } = props.data;

  const isAgent = props.isAgent;
  console.log(isAgent, 'isAgent')

  const [openDialog, setOpenDialog] = useState(false); // State for dialog open/close
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [loadingLead, setLoadingLead] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  async function downloadLead() {
    setLoadingLead(true);
    try {
      const response = await axiosInstance.get(
        "/api/view/leads/" + listing_id,
        {
          responseType: "blob", // Tell Axios to expect a blob response
        }
      );

      // Save the blob as a file using FileSaver.js
      saveAs(response.data, `leads-${listing_id}.xlsx`);

      setLoadingLead(false);
    } catch (error) {
      console.error(error);
    }
  }


  // Function to handle opening the dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Function to handle closing the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Function to delete the listing
  async function deleteListing() {
    try {
      setLoading(true); // Set loading to true when delete function starts
      const response = await axiosInstance.delete(
        `/api/listing/${listing_id}`,
        {
          withCredentials: true,
        }
      );
      console.log(response);
      props.reload()
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 404) {
        props.reload()
      }
      window.alert("Unable to delete");
    } finally {
      setLoading(false); // Set loading to false when delete function finishes
    }
  }

  function formatTimestamp(timestamp) {
    const now = new Date();
    const date = new Date(timestamp);
    const diff = Math.floor((now - date) / (1000 * 60 * 60 * 24));

    if (diff === 0) {
      return "Today";
    } else if (diff === 1) {
      return "Yesterday";
    } else if (diff <= 7) {
      return `${diff} days ago`;
    } else {
      return date.toLocaleDateString();
    }
  }


  async function updateState(state) {
    setLoading(true)
    try {
      const response = await axiosInstance.put('/api/listing/updateState/' + listing_id, { state: state });
      console.log(response);

      if (response.status === 200) {
        props.reload();
      } else if (response.data && response.data.success === false && response.data.cause === 'inactiveLimit') {
        setNotificationVisible(true); // Update state to show notification (replace with your state management)
      } else {
        // Handle other unexpected errors
        console.error('Unexpected error:', response);
        // Optionally, display a generic error message to the user
      }
    } catch (error) {
      console.error('Error updating state:', error);

      if (error.response.status === 403) {
        if (error.response.data.cause === 'inactiveLimit') {
          setNotificationVisible(true);
        }
        if(error.response.data.cause === 'activeLimit'){
          setNotificationVisible(true);
        }
      }
      // Optionally, display a generic error message to the user
    } finally{
      setLoading(false)
    }
  }
  if (notificationVisible) {
    return (
      
        
          <div className=" bg-white fixed top-0 left-0 w-full h-full z-10">
            <div className=" px-6 lg:px-8">

              <div className=" mt-8  rounded-3xl ring-1 ring-gray-200 sm:mt-12 lg:mx-0 lg:flex ">
                <div className="p-8 sm:p-10 lg:flex-auto">
                  <h1 className="text-2xl font-bold tracking-tight text-gray-900"> Pricing</h1>
                  <p className="mt-6 text-base leading-7 text-gray-600">Limit reached to set property as {isPublic === 1 ? "inactive":"active"}. Upgrade your plan to set more listings as {isPublic === 1 ? "inactive":"active"}.</p>
                  
                 
                </div>
                <div className=" p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                  <div
                    className="rounded-2xl py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                    <div className="mx-auto max-w-xs px-8 capitalize">
                      <p className="text-base font-semibold text-gray-600">purchase listings</p>
                      <p className="mt-6 mb-12 flex items-baseline justify-center gap-x-2">
                        <span className="text-3xl font-bold tracking-tight text-gray-900 "><span className="line-through px-2"><FontAwesomeIcon icon={faIndianRupeeSign} />799</span><br/><span className="italic text-gray-700"><FontAwesomeIcon icon={faIndianRupeeSign} />499*</span></span>
                        <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600"></span>
                      </p>
                      <a href="/pricing" className="bg-blue-600 text-white rounded-sm py-2 w-full block my-6">Get Started →</a>
                      <p className="mt-6 text-xs leading-5 text-gray-600">Invoices and receipts available for easy company reimbursement
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        

      
    );
  }

  return (
    <div className={`${isPublic === 1 ? " shadow-green-500" : "shadow-grey-800"} relative  rounded overflow-hidden shadow flex flex-col justify-between bg-primary`}>
      <div className="relative  overflow-hidden">
        {(isPublic === 1 || isPublic === 2) && <div className={`absolute opacity-90 top-[10px] rotate-[40deg] shadow-lg right-[-40px] grid place-items-center ${isPublic === 1 ? 'bg-green-500' : 'bg-hover'} w-[150px] h-[40px] bg-green  `}>
          <h1 className="text-white text-lg font-bold ">{isPublic === 1 ? "Active" : "Sold"}</h1>
        </div>}
        <img onClick={(event) => { props.openDialog(listing_id) }}
          src={process.env.REACT_APP_BACKEND + thumbnail}
          alt={listing_name}
          className="w-full h-48 object-cover"
        />

      </div>
      <div className="px-6 pt-4">
        <Link to={`/property/${listing_id}`}>

          <div className="font-bold text-xl mb-2">{listing_name}</div>
        </Link>

      </div>
      <div onClick={(event) => { props.openDialog(listing_id) }} className="px-6 py-4">
        <p className="text-gray-700">Category: {category}</p>
        <p className="text-gray-700">Subcategory: {subcategory}</p>
        <p className="text-gray-700">City: {city}</p>
        <p className="text-gray-700">Landmark: {landmark}</p>
        <p className="text-gray-700">Price: ₹{price}</p>
        <p className="text-gray-700">date: {formatTimestamp(createdAt)}</p>
      </div>
      <div className="flex justify-between items-center bg-primary border-t-[1px] px-6 py-4">
        {/* <LoadingButton
          onClick={downloadLead}
          loading={loadingLead}
          style={{ background: "#3b82f6", color: "white" }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          variant="oulined"
        >
          Leads
        </LoadingButton> */}

        {/* <button onClick={() => navigate('/dashboard/edit/' + listing_id)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Edit
          </button>
          <button onClick={handleOpenDialog} className="bg-red-500 ml-2 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Delete
          </button> */}
        <ElevatedButton >
          <Dropdown variant='text'>
            <MenuButton className=" hover:bg-hover text-heading hover:text-white py-2 px-4 rounded" >
              More
            </MenuButton>
            <Menu className="bg-white p-2 rounded shadow-lg border-[1px] ">
              <LoadingButton
                onClick={downloadLead}
                loading={loadingLead}
                sx={{


                  justifyContent: 'start',
                  width: "100%",

                }}

                variant="oulined"
              >
                Leads
              </LoadingButton>
              {/* <MenuItem className="bg-primary  hover:outline outline-[1px] cursor-pointer text-heading  py-2 px-4 rounded" onClick={downloadLead}>Download Leads</MenuItem> */}
              <MenuItem
                className="bg-primary  hover:outline outline-[1px] cursor-pointer text-heading  py-2 px-4 rounded"
                onClick={() => navigate("/dashboard/edit/" + listing_id)}
              >
                Edit Listing
              </MenuItem>
              <MenuItem
                className="bg-primary text-heading hover:outline hover:bg-red-500  outline-[1px] cursor-pointer hover:text-white  py-2 px-4 rounded"
                onClick={handleOpenDialog}
              >
                Delete Listing
              </MenuItem>
            </Menu>
          </Dropdown></ElevatedButton>
          {isPublic === 1 ?
        
            <div className="flex gap-2">
            
            
          {/* <button onClick={()=>{updateState(0)}} className="w-fit h-full bg-heading rounded text-white p-2">
            Mark Inactive
          </button>  */}
          {isAgent && <ElevatedButton buttonClick={()=>{updateState(0)}} label="Set Inactive" buttonClassName="w-fit h-full bg-heading rounded text-heading p-2" />}
          {/* <button onClick={()=>{updateState(2)}} className="w-fit h-full bg-heading rounded text-white p-2">
            Mark as Sold
          </button>  */}
        
     


            {/* <button onClick={()=>{updateState(0)}} className="w-fit h-full bg-heading rounded text-white p-2">
            Mark Inactive
          </button>  */}
             {/* <button onClick={()=>{updateState(2)}} className="w-fit h-full bg-heading rounded text-white p-2">
            Mark as Sold
          </button>  */}
            <ElevatedButton buttonClick={() => { updateState(2) }} label="Sold" buttonClassName="w-fit h-full bg-heading rounded text-heading p-2" />

          </div>




          : isPublic === 0 ?

            <div className="flex gap-2">


              {/* <button onClick={()=>{updateState(0)}} className="w-fit h-full bg-heading rounded text-white p-2">
            Mark Inactive
          </button>  */}
              <ElevatedButton buttonClick={() => { updateState(1) }} label="Set Active" buttonClassName="w-fit h-full bg-heading rounded text-heading p-2" />
              {/* <button onClick={()=>{updateState(2)}} className="w-fit h-full bg-heading rounded text-white p-2">
            Mark as Sold
          </button>  */}
              <ElevatedButton buttonClick={() => { updateState(2) }} label="Sold" buttonClassName="w-fit h-full bg-heading rounded text-heading p-2" />

            </div> :
            <div className="flex gap-2">


              {/* <button onClick={()=>{updateState(0)}} className="w-fit h-full bg-heading rounded text-white p-2">
               Mark Inactive
             </button>  */}
             {isAgent && <ElevatedButton buttonClick={()=>{updateState(0)}} label="Set Inactive" buttonClassName="w-fit h-full bg-heading rounded text-heading p-2" />}
             {/* <button onClick={()=>{updateState(2)}} className="w-fit h-full bg-heading rounded text-white p-2">
               Mark as Sold
             </button>  */}
              <ElevatedButton buttonClick={() => { updateState(1) }} label="Set Active" buttonClassName="w-fit h-full bg-heading rounded text-heading p-2" />

            </div>



        }

      </div>
      {/* Render AlertDialog component */}
      <AlertDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleAgree={deleteListing}
      />
      {/* Render SimpleBackdrop component with conditional rendering based on loading state */}
      {loading && <SimpleBackdrop />}
    </div>
  );
};

export default PropertyCard;
