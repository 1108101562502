import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Pricing = () => {
    return (
        <div className="bg-gradient-to-r h-[650px] font-inter from-blue-950 to-black py-12">
            <div className="max-w-7xl  mx-auto px-4 sm:px-6 lg:px-8 font-inter">
                <div className="text-center">
                    {/* <h2 className="mt-2 text-3xl text-blue-100 leading-8 font-extrabold tracking-tight sm:text-3xl">Pricing</h2>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-blue-100 sm:text-4xl">
                        BUSINESS PRICING TABLE
                    </p> */}
                    <p className="mt-4 max-w-2xl text-xl text-gray-200 lg:mx-auto">
                        Affordable and flexible pricings for every need.
                    </p>
                </div>
                <div className="mt-10 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                    {/* Pricing Plan Card */}
                    {/* <div className="max-w-sm bg-transparent border border-gray-700 rounded-lg shadow-lg overflow-hidden ">
                        <div className="px-8 py-6 ">
                            <h3 className="text-center text-2xl font-semibold text-gray-100">Listings</h3>
                            <p className="text-center text-gray-100 mt-4"><FontAwesomeIcon icon={faIndianRupeeSign} />499/month</p>
                            <div className="mt-6">
                                <ul className="space-y-2">
                                    <li className="flex items-center">
                                        <input type="checkbox" className="text-green-500" checked disabled />
                                        <span className="ml-3 text-gray-100">5 active listings</span>
                                    </li>

                                    <li className="flex items-center">
                                        <input type="checkbox" className="text-green-500" checked disabled />
                                        <span className="ml-3 text-gray-100">2x impressions</span>
                                    </li>

                                    <li className="flex items-center">
                                        <input type="checkbox" className="text-red-" checked disabled />
                                        <span className="ml-3 text-gray-100">Active customer support</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-6">
                                <a href="tel:8989969422">
                                    <button className="w-full px-4 py-2 text-white bg-heading rounded-lg hover:bg-sky-600 focus:outline-none">
                                        Get Started
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div> */}
                    {/* Repeat for other plans */}
                    {/* <div className="max-w-sm bg-transparent border border-gray-700 rounded-lg shadow-lg overflow-hidden">
                        <div className="px-8 py-8">
                            <h3 className="text-center text-2xl font-semibold text-gray-100">Feature</h3>
                            <p className="text-center text-gray-100 mt-4"><FontAwesomeIcon icon={faIndianRupeeSign} />599/month</p>
                            <div className="mt-6">
                                <ul className="space-y-2">
                                    <li className="flex items-center">
                                        <input type="checkbox" className="text-green-500" checked disabled />
                                        <span className="ml-3 text-gray-100">5 feature tags</span>
                                    </li>
                                    <li className="flex items-center">
                                        <input type="checkbox" className="text-green-500" checked disabled />
                                        <span className="ml-3 text-gray-100">1 month validity</span>
                                    </li>
                                    <li className="flex items-center">
                                        <input type="checkbox" className="text-green-500" checked disabled />
                                        <span className="ml-3 text-gray-100">3x higher impressions</span>
                                    </li>

                                </ul>
                            </div>
                            <div className="mt-6">
                                <a href="tel:8989969422">
                                    <button className="w-full px-4 py-2 text-white bg-heading rounded-lg hover:bg-sky-500 focus:outline-none">
                                        Get Started
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div> */}
                    <div className="max-w-sm bg-transparent border border-gray-700 rounded-lg shadow-lg overflow-hidden">
                        <div className="px-8 py-8">
                            <h3 className="text-center text-2xl font-semibold text-gray-100">additional features</h3>
                            {/* <p className="text-center text-gray-100 mt-4"><FontAwesomeIcon icon={faIndianRupeeSign} />999/month</p> */}
                            <div className="mt-6">
                                <ul className="space-y-2">
                                    <li className="flex items-center">
                                        <input type="checkbox" className="text-green-500" checked disabled />
                                        <span className="ml-3 text-gray-100">more listings</span>
                                    </li>
                                    <li className="flex items-center">
                                        <input type="checkbox" className="text-green-500" checked disabled />
                                        <span className="ml-3 text-gray-100">feature tokens</span>
                                    </li>
                                    <li className="flex items-center">
                                        <input type="checkbox" className="text-green-500" checked disabled />
                                        <span className="ml-3 text-gray-100">3x impressions</span>
                                    </li>
                                    <li className="flex items-center">
                                        <input type="checkbox" className="text-green-500" checked disabled />
                                        <span className="ml-3 text-gray-100">Active customer support</span>
                                    </li>

                                </ul>
                            </div>
                            <div className="mt-6">
                                <a href="tel:8989969422">
                                    <button className="w-full px-4 py-2 text-white bg-heading rounded-lg hover:bg-sky-500 focus:outline-none">
                                        Contact Customer Support
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Pricing