import { useState } from "react"
import axiosInstance from "../axiosInstance";
import cookies from 'js-cookie'
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";


export default function SetSeller(props){
    const navigate = useNavigate();
    const [form,setForm] = useState('')
    const user_id = cookies.get('id');
    const isSeller = cookies.get('seller');
    const [BuilderVisible, setBuilderVisible] = useState(false)

    const [wait, setWait]=useState((props.builder && !props.isApproved))

    const [builderForm, setBuilderForm]= useState({});


    function handleForm(e){
        setForm(e.target.name)
    }
    console.log(form);

    async function handleSubmit(){

        if(!form){
            return
        }

        if(form === 'builder'){
            return setBuilderVisible(true)
        }

        try{

            const response =await axiosInstance.post('/api/user/setSeller',{seller_type:form}, {
                withCredentials:true
            } );
            if(response.status=== 200){
                const data = await axiosInstance.post('api/user/refreshToken',{id: user_id} ,{
                   withCredentials:true
                } )

                if(data.status===200){
                    window.location.reload()
                }
            }
        }catch(error){
            console.log(error)
        }

       

    }

    console.log(builderForm)

    async function handleBuilderForm(){
      try {
        
        const formData = new FormData();
        formData.append('builderName', builderForm.builderName);
        formData.append('builderDescription', builderForm.builderDescription);
        formData.append('builderLogo', builderForm.builderLogo )

        const response = await axiosInstance.post('/api/builder',formData,{
            withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        }
        })

        if(response.status===201){
          setBuilderVisible(false)
          setWait(true)
        }
       
        console.log(response)
      } catch (error) {
        console.log(error)
      }
        // const response = axiosInstance.post('/builder')
    }
  

    return(
       <div className="w-full font-nunito h-screen grid p-6 place-items-center">
       { !BuilderVisible && !wait   &&  <div className="capitalize border-[1px] p-8 rounded-xl shadow text-2xl">
        You are a
            <label className="grid grid-cols-2 gap-6 p-4 rounded-xl">
            <button name="owner" onClick={handleForm} className={ `p-4 ${form === 'owner' && ' text-white bg-heading'} border-[1px] shadow rounded-xl border-black`}>Owner</button>
            <button name="agent" onClick={handleForm} className={ `p-4 ${form === 'agent' && 'text-white bg-heading'} border-[1px] rounded-xl border-black`}>Broker</button>
             <button name="builder" onClick={handleForm} className={ `p-4 ${form === 'builder' && 'text-white bg-heading'} border-[1px] rounded-xl border-black`}>Builder</button>
            {/*<button name="flatmate" onClick={handleForm} className={ `p-4 ${form === 'flatmate' && 'text-white bg-heading'} border-[1px] rounded-xl border-black`}>Flatmate</button> */}

            </label>
            <button className={`p-4 ${form === '' && 'bg-gray-300  text-gray-400'} w-full  border-[1px]  bg-blue-800 text-white rounded-xl`}  onClick={handleSubmit}>Next</button>
        </div>}


        {BuilderVisible && (
  <div className="bg-white shadow-md rounded-lg p-6 grid gap-6 w-full">
    <div>
      <label htmlFor="builder_name" className="block text-gray-700 font-bold mb-2">
        Company Name
      </label>
      <input
        type="text"
        id="builder_name"
        name="builder_name"
        value={builderForm.builder_name}
        onChange={(e) =>
          setBuilderForm((prevBuilderForm) => ({
            ...prevBuilderForm,
            builderName: e.target.value,
          }))
        }
        placeholder="Enter your company name"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
    </div>

    <div>
      <label htmlFor="builder_description" className="block text-gray-700 font-bold mb-2">
        Description
      </label>
      <textarea
        id="builder_description"
        name="builder_description"
        value={builderForm.builder_description}
        onChange={(e) =>
          setBuilderForm((prevBuilderForm) => ({
            ...prevBuilderForm,
            builderDescription: e.target.value,
          }))
        }
        placeholder="Enter a description"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none"
        rows={4}
      ></textarea>
    </div>

    <div>
      <label htmlFor="thumbnail" className="block text-gray-700 font-bold mb-2">
        Logo
      </label>
      <div className="flex items-center">
        <label
          htmlFor="thumbnail"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
        >
          Upload Logo
        </label>
        {builderForm.builderLogo && (
          <span className="ml-4 text-gray-700">{builderForm.builderLogo[0].name}</span>
        )}
      </div>
      <input
        type="file"
        id="thumbnail"
        name="thumbnail"
        accept=".png, .jpg"
        onChange={(e) =>
          setBuilderForm((prevBuilderForm) => ({
            ...prevBuilderForm,
            builderLogo: e.target.files,
          }))
        }
        className="hidden"
      />
    </div>

    <button
      className={`p-4 w-full border-[1px] bg-blue-800 text-white rounded-xl ${
        form === '' && 'bg-gray-300 text-gray-400'
      }`}
      onClick={handleBuilderForm}
    >
      Next
    </button>
  </div>
)}


{
  wait && <div className="grid gap-4 p-8">
    <h2>Your Builder Application has been submitted</h2>
    <p>Our representive will contact you soon</p>
    </div>
}
       </div>
    )
}