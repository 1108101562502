import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faBuilding } from "@fortawesome/free-regular-svg-icons";
import logo from '../assets/logo.svg';
import logo1 from "../assets/logo2.svg"
import indiaImg from '../assets/india.png';
import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faTwitter,
    faYoutube,
    faXTwitter
} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
export default function Footer() {
    const location = useLocation();
    const seller = localStorage.getItem('seller');

    if (location.pathname === '/login') {
        return null;
    }
    // Determine if the footer should be white with black text
    const isSpecialPage = ["/pricing", "/about", "/contact", "/privacy", "/terms", "/profile" , "/ourteam" , "/hotdeals"].includes(location.pathname);
    const footerBgClass = isSpecialPage ? "bg-gray-100 text-gray-900" : "bg-gradient-to-r from-blue-950 to-black text-text";
    const textClass = isSpecialPage ? "text-black" : "text-white";
    const logoToDisplay = isSpecialPage ? logo1 : logo;
    return (
        <div className={`hidden lg:block w-full ${footerBgClass} font-inter`}>
            <div className="p-8 flex mx-8 gap-8 items-center border-b-[1px] border-gray-600 justify-evenly">
                <img src={logoToDisplay} className="w-[200px]" alt="Logo" />
                <p className={`text-3xl font-inter ${textClass}`}>INDIA'S FASTEST GROWING REAL ESTATE PLATFORM</p>
                <button className="bg-gray-600 text-white rounded p-2 text-lg font-inter ">
                    <FontAwesomeIcon icon={faBuilding} className="mr-2 text-xl" />
                    <Link to={seller ? '/dashboard' : "/login"}>List your Property</Link>
                </button>
            </div>
            <div className="grid place-items-center">
                <div className="grid py-4 w-4/5 border-b-[1px] border-gray-600 lg:grid-cols-3 gap-4 p-2">
                    <div>
                        <h1 className={`pb-8 pt-4 text-xl ${textClass}`}>Get Connected</h1>
                        <div className="grid w-2/3 grid-cols-5">
                            <a href="https://www.facebook.com/people/Nextopsonin/61554406271860/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className={`text-3xl ${textClass}`} icon={faFacebook} /></a>
                            <a href="https://www.instagram.com/nextopson.in/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className={`text-3xl ${textClass}`} icon={faInstagram} /></a>
                            <a href="https://www.linkedin.com/company/nextopson-in/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className={`text-3xl ${textClass}`} icon={faLinkedin} /></a>
                            <a href="https://twitter.com/NextOpson" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className={`text-3xl ${textClass}`} icon={faXTwitter} /></a>
                            <a href="https://youtube.com/@Nextopson" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className={`text-3xl ${textClass}`} icon={faYoutube} /></a>
                        </div>
                    </div>
                    <div className="p-4 grid gap-2 text-gray-500">
                        <h1 className="uppercase font-bold mb-4">Company</h1>
                        <a href="/pricing" className={textClass}>Pricing</a>
                        {/* <a href="/about" className={textClass}>About Us</a> */}
                        <a href="/contact" className={textClass}>Contact Us</a>
                        <a href="/privacy" className={textClass}>Privacy policy</a>
                        <a href="/terms" className={textClass}>Terms and Conditions</a>
                    </div>
                    <div className="p-4 grid gap-2 text-gray-500">
                        <h1 className="uppercase font-bold">Contact</h1>
                        <a href="mailto:support@nextopson.in" className={textClass}><FontAwesomeIcon className="mr-2 text-lg" icon={faEnvelope} />support@nextopson.in</a>
                        <a href="tel:8989969422" className={`flex ${textClass}`}><FontAwesomeIcon className="mr-2 text-lg" icon={faPhone} /> +91 89899 69422</a>
                    </div>
                </div>
            </div>
            <div className="grid place-items-center p-4 text-gray-300">
                <h1 className={`text-sm flex items-center gap-1 ${textClass}`}>Copyright © 2024 All rights reserved with <span className="font-bold">Exson proptech pvt. ltd.</span><span className="flex items-center gap-2 border-l-[1px] px-1 h-5 border-text">Made in INDIA <img src={indiaImg} className="w-7" alt="India" /></span> </h1>
            </div>
        </div>
    );
}
