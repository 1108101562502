import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/leaflet.css';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
  });

export default function CompareMap({coordinates}) {
   // Initial map position (latitude, longitude)
    console.log(coordinates)
  return (
    <div className='min-w-[400px] h-full'>{
        coordinates &&
      <MapContainer
       
        zoom={12}
        scrollWheelZoom={false}
        style={{ width: '600px', height:'100%' }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
       
        
           { coordinates.map((value)=>{
               return ( <Marker position={[value.location.latitude, value.location.longitude]} icon={DefaultIcon}>
                <Popup setOpen={true}>{value.name}</Popup>
              </Marker>)
            })}
            <Bounds coordinates={coordinates}/>
        
      </MapContainer>}
    </div>
  );
}


function Bounds({coordinates}){
    const map = useMap()
    const bounds = coordinates.map((val)=>{
        return [val.location.latitude, val.location.longitude]
    })

    map.fitBounds(bounds)
    
    return null
}