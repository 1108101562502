import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import TextField from "@mui/material/TextField";
import {  MenuItem, Select } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSearch } from "@fortawesome/free-solid-svg-icons";
import Drawer from "@mui/material/Drawer";
import PropertyCard from "./listingCards";
import Cookies from "js-cookie";
import ListingDialog from "./listingDialog";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import FilterDrawer from "./filterDrawer";

export default function MyData(props) {
  
  const id = localStorage.getItem("id");
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [drawerOpen, setDrawer] = useState(false);

  const navigate = useNavigate();
  
  
  const queryClient = useQueryClient();
  
  const userInfo = queryClient.getQueryData(['userInfo'])
  const isAgent = !!(userInfo.seller_type === 'agent') 
 console.log(isAgent)

  const [filters, setFilters] = useState({
    query: searchParams.get("query") || "",
    category: searchParams.get("category") || "",
    subcategory: searchParams.get("subcategory") || "",
    seller: id || "3",
    priceMax: searchParams.get("priceMax") || "",
    priceMin: searchParams.get("priceMin") || "",
    listing_id: searchParams.get("listing_id") || "",
    city: searchParams.get("city") || "",
    preference: searchParams.get("preference") || "",
    sellerType: searchParams.get("sellerType") || "",
    landmark: searchParams.get("landmark") || "",
    bedroom: searchParams.get("bedroom") || "",
    bathroom: searchParams.get("bathroom") || "",
    areaMax: searchParams.get("areaMax") || "",
    areaMin: searchParams.get("areaMin") || "",
    furniture: searchParams.get("furniture") || "",
    parking: searchParams.get("parking") || "",
    sort: searchParams.get("sort") || "relevance",
    page: searchParams.get("page") || 1,
    pageSize: searchParams.get("pageSize") || 30,

    public: (props.pageType=== 'active') ? 1 : ((props.pageType === 'all') ? 0 : 2),
  });


  useEffect(()=>{
    setFilters((prev)=>({
      query: searchParams.get("query") || "",
      category: searchParams.get("category") || "",
      subcategory: searchParams.get("subcategory") || "",
      seller: id || "3",
      priceMax: searchParams.get("priceMax") || "",
      priceMin: searchParams.get("priceMin") || "",
      listing_id: searchParams.get("listing_id") || "",
      city: searchParams.get("city") || "",
      preference: searchParams.get("preference") || "",
      sellerType: searchParams.get("sellerType") || "",
      landmark: searchParams.get("landmark") || "",
      bedroom: searchParams.get("bedroom") || "",
      bathroom: searchParams.get("bathroom") || "",
      areaMax: searchParams.get("areaMax") || "",
      areaMin: searchParams.get("areaMin") || "",
      furniture: searchParams.get("furniture") || "",
      parking: searchParams.get("parking") || "",
      sort: searchParams.get("sort") || "relevance",
      page: searchParams.get("page") || 1,
      pageSize: searchParams.get("pageSize") || 30,
  
      public: (props.pageType=== 'active') ? 1 : ((props.pageType === 'all') ? 0 : 2),
    }))
  },[searchParams,window.location.pathname])

  const[queryTemp, setQueryTemp] = useState(filters.query)
  

 
 
  const [detailDialog, SetDetailDialog] = useState(false);
  const [detailListingId, setDetailListingId] = useState(null)
  async function handleDialogOpen(id){
   SetDetailDialog(true);
   setDetailListingId(id)
  }


  
  const fetchData = async () => {
    const params = new URLSearchParams(filters);
      const response = await axiosInstance.get("/api/search/basic", {
        params: params,
        withCredentials:true
      });
      return response.data
      
    
  };
 

  const {data:results, isLoading: isResultsLoading, refetch } = useQuery({queryKey:['results',  window.location.pathname,filters, props.pageType, searchParams],queryFn:fetchData, 
  refetchInterval: 60000, })




  function scrolltoTop() {
    if (window.scrollY > window.innerHeight) {
      window.scrollTo(0, 0);
    }
  }

  useEffect(() => {
    scrolltoTop();
  }, [results]);

  
  let pageCount
  if(!isResultsLoading){
    pageCount = Math.ceil( results.count / 30) 
  }else{
    pageCount= 1;
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchParams((prevState)=>({...Object.fromEntries(prevState), query:event.target.value}))
      setFilters(prev=>({...prev, query:event.target.value}))
    }
  };

  const handleSortChange = (value) => {
    setFilters((prev)=>({...prev, sort:value}));
  };


  const handlePageChange=(page)=>{
    setSearchParams((prevState)=>({...Object.fromEntries(prevState), page:page}))
    setFilters((prev)=>({...prev, page:page}))
  }
  

  const [menuIsOpen, setOpen] = useState(true);

  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      // Scrolling down
      setOpen(false);
    } else {
      // Scrolling up
      setOpen(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  window.addEventListener("scroll", handleScroll);

  return (
    <div className="text-text bg-primary  scroll-smooth w-full">
      <ListingDialog open={detailDialog}
        onClose={SetDetailDialog} propertyId={detailListingId}/>
      <FilterDrawer filters={filters} setFilters={setFilters} drawerOpen={drawerOpen} setDrawer={setDrawer}/>

      <div className=" w-full h-full bg-primary font-inter  flex flex-col justify-center   items-center">
        <div className="w-full   p-2 block lg:grid grid-cols-5 gap-4 place-items-center lg:w-full rounded">
          <button
            className={`${
              !(window.innerWidth > 720 || menuIsOpen) && "hidden"
            } mr-[2px] h-[50px] bg-heading fixed bottom-16 lg:bottom-0 lg:left-0 left-1 text-white z-10  lg:relative w-1/2 lg:w-[200px] grid  place-items-center col-span-1 rounded border-gray-400 font-nunito border-[1px] `}
            onClick={() => {
              setDrawer(true);
            }}
          >
            Filters
          </button>

          <div className="w-full col-span-3 justify-center items-center   ">
            <TextField
              label="Search..."
              variant="outlined"
              value={queryTemp}
             
              onChange={(e) => setQueryTemp(()=>e.target.value)}
              onKeyDown={handleKeyDown}
              fullWidth
              InputProps={{
                style: {
                  backgroundColor: "white", // Set background color
                  borderRadius: "5  px", // Set border radius
                  height: "50px",
                  // Add any other custom styles here
                },
                endAdornment: (
                  // You can add an icon or other element as end adornment
                  <FontAwesomeIcon icon={faSearch} />
                ),
              }}
              InputLabelProps={{
                shrink: true, // This shrinks the label when the input is focused or has value
              }}
            />
          </div>
          <div className="flex w-full lg:w-fit lg:items-center justify-between">
           

            <div
              className={`${
                !(window.innerWidth > 720 || menuIsOpen) && "hidden"
              } col-span-1 fixed bottom-16 right-1 lg:bottom-0 lg:right-0 z-10 lg:relative bg-white lg:w-full flex justify-end`}
            >
              <Select
                style={{
                  width:
                    window.innerWidth > 710 ? "200px" : window.innerWidth / 2,
                  marginLeft: "2px",
                  height: "50px",
                  color: "white",
                }}
                value={filters.sort}
                onChange={(e) => handleSortChange(e.target.value)}
                sort
                variant="outlined"
                label="Listed by"
                fullWidth
                className="bg-heading"
              >
                <MenuItem value="relevance">Relevance</MenuItem>
                <MenuItem value="priceAscending">Price low to high</MenuItem>
                <MenuItem value="priceDescending">Price high to low</MenuItem>

                <MenuItem value="timeDescending">Newest first</MenuItem>

                <MenuItem value="viewDescending">Most popular</MenuItem>
              </Select>
            </div>
          </div>
        </div>
          
      </div>

      <div className="lg:flex bg-primary relative ">
        <div className="w-full">
          <h1 className="px-4 py-2 capitalize font-semibold">
            {  results?.count} results found
          </h1>
          <div
            id="searchListings"
            className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 place-items-stretch  gap-4 p-4 bg-primary   "
          >
            {isResultsLoading ? ( // Display skeleton loading state while loading
              <div className="grid h-[400px] cols-span-2  place-items-center">
                {/* Add your skeleton loading state here */}
                <h1>Loading...</h1>
              </div>
            ) : // Render property cards once loading is false
            results.results ? (
              results.results.map((data) => (
                <PropertyCard
                  key={data.listing_id}
                  data={data}
                  reload={refetch}
                  openDialog={handleDialogOpen}
                  isAgent={isAgent}
                />
              ))
            ) : (
              <div className="grid h-1/2 place-items-center">
                <h1>No matches found</h1>
              </div>
            )}
          </div>
        </div>
      </div>
      <Stack className="flex bg-primary items-center p-4" spacing={2}>
        <Pagination
          count={pageCount}
          defaultPage={1}
          page={filters.page}
          onChange={(e, page) => {
            handlePageChange( page);
          }}
          color="primary"
        />
      </Stack>
    </div>
  );
}
