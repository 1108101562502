import { useNavigate, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import indiaImg from "../assets/india.png";
import moreImg from "../assets/more.png"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { faArrowRight, faBars } from "@fortawesome/free-solid-svg-icons";
import phone from "../assets/telephone.png";
import logo from "../assets/nextopson-logo.webp";
import { useState } from "react";
import axiosInstance from "../axiosInstance";
import { useQuery } from "@tanstack/react-query";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// import Person2Icon from '@mui/icons-material/Person2';
// import LoginIcon from '@mui/icons-material/Login';
import { CiLogin } from "react-icons/ci";
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
// import HomeIcon from '@mui/icons-material/Home';
import { GoHome } from "react-icons/go";
// import SearchIcon from '@mui/icons-material/Search';
import { CiSearch } from "react-icons/ci";
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { CiUser } from "react-icons/ci";
import { LuLayoutDashboard } from "react-icons/lu";
import MobileNav from "./mobileNav";
export default function Header() {

  const navigate = useNavigate();
  const [menuIsOpen, setOpen] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsDrawerOpen(false);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCallUsClick = () => {
    setIsDialogOpen(true);
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      // Scrolling down
      setOpen(false);
    } else {
      // Scrolling up
      setOpen(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  window.addEventListener("scroll", handleScroll);

  function scrolltoTop(path) {
    if (window.location.href !== path) {
      navigate(path);
    }

    if (window.scrollY > window.innerHeight) {
      window.scrollTo(0, 0);
    }
  }

  const userId = localStorage.getItem("id");
  const seller = localStorage.getItem("seller");

  return (
    <div className=" w-full text-text flex flex-col items-center bg-primary">
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <button className="lg:hidden ml-3 mt-3 mb-2 bg-heading text-white rounded px-2 py-2 shadow shadow-heading text-sm font-inter ">
              <FontAwesomeIcon icon={faBuilding} className="mr-2 text" />
              <Link
                to={seller ? "/dashboard?panel=listings" : "/login"}
              >
                List Property{" "}
              </Link>
            </button>
            <ListItem button onClick={() => handleNavigation('/pricing')}>
              <AttachMoneyOutlinedIcon className="mr-2" />  <ListItemText primary="Pricing" />
            </ListItem>
            {/* <ListItem button onClick={() => handleNavigation('/about')}>
              <InfoOutlinedIcon className="mr-2" />  <ListItemText primary="About Us" />
            </ListItem> */}
            <ListItem button onClick={() => handleNavigation('/contact')}>
              <MessageOutlinedIcon className="mr-2" /> <ListItemText primary="Contact Us" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/privacy')}>
              <PrivacyTipOutlinedIcon className="mr-2" />  <ListItemText primary="Privacy and Policy" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/terms')}>
              <GavelOutlinedIcon className="mr-2" /> <ListItemText primary="Terms and Conditions" />
            </ListItem>
            <ListItem button onClick={() => handleCallUsClick()}>
              <AddIcCallOutlinedIcon className="mr-2" />
              <ListItemText primary="Call us" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/faqAccordion')}>
              <ContactSupportOutlinedIcon className="mr-2" /> <ListItemText primary="F.A.Q" />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <p>Contact: +91 8989969422</p>
        </DialogContent>
        <DialogActions className="flex justify-between ">
          <Button href="tel:+918989969422">Call</Button>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <nav className="w-full h-16 px-2 pt-2 rounded-full text-text  bg-primary  flex justify-center items-center lg:h-fit lg:py-2 lg:w-full lg:rounded-none lg:justify-between">
        <div className="flex w-full px-4  items-center justify-between">
          <Link to={"/"} className="flex w-40  lg:mt-2 lg:w-46">
            <img src={logo} alt="nextopson logo" className="w-full lg:ml-14" />
          </Link>

          {/* <button className="lg:hidden bg-heading text-white rounded px-2 py-2 shadow shadow-heading text-sm font-inter ">
            <FontAwesomeIcon icon={faBuilding} className="mr-2 text" />
            <Link
              to={seller ? "/dashboard?panel=listings" : "/login"}
            >
              List Property{" "}
            </Link>
          </button> */}
          <div
            onClick={toggleDrawer(true)}
            className="min-w-16 lg:hidden rounded-b-xl p-2 border-black flex flex-col justify-center items-center text-2xl text-white"
          >
            {/* <img src={moreImg} alt="dashboard icon" className="w-6" /> */}
            <FontAwesomeIcon className="text-text text-2xl" icon={faBars}/>
          </div>
        </div>
        <div className="lg:h-fit font-nunito">

          <div className="hidden h-full lg:flex  items-center justify-between ">
            <Link to={(userId
              ? seller === "1"
                ? "/dashboard/upload"
                : "/dashboard?panel=listings"
              : "/login?redirectFrom=/dashboard?panel=listings")} className="w-60 h-full border-l-2   flex items-center justify-center">
              <FontAwesomeIcon icon={faBuilding} className=" text-2xl" />
              <div className="flex ml-5 flex-col items-start">
                <h4
                  className=""
                >
                  List your property{" "}
                  <span className="bg-heading text-white rounded-sm mb-4 p-1">
                    Free
                  </span>{" "}
                </h4>
                <h4 className="text-sm text-gray-400">
                  Get perfect Buyer/Tenant
                </h4>
              </div>
            </Link>
            <div className="w-60 h-full border-l-2 flex items-center justify-center">
              <img src={phone} alt="phone logo" className="w-6 " />
              <div className=" lg:flex lg:flex-col ml-4">
                <a
                  href="tel:+918989969422 "
                  className=" text-sm flex items-center gap-2"
                >
                  <img src={indiaImg} alt="india map logo" className="w-6"></img> +91 8989969422
                </a>
                <a href="tel:+918989969422 " className="text-gray-400  text-sm">
                  call for any support
                </a>
              </div>
            </div>

            {userId ? (
              // <Link to={"/dashboard"} className="">
              //   <div className="login-btn  h-full  border-black w-fit px-4 py-2 m-1  flex justify-center items-center text-xl cursor-pointer hover:bg-hover text-white border-white bg-heading rounded-sm ">
              //     <img src={menuImg} alt="dashboard icon" className="w-6 invert mr-2"></img>
              //     Dashboard
              //   </div>
              // </Link>
              // <ElevatedButton label="Dashboard" to="/dashboard" iconImg={menuImg} className="mr-2" buttonClassName="login-btn h-full w-fit px-2 py-2 flex justify-center items-center text-xl cursor-pointer  text-heading  bg-heading  " />
              <Link to="/dashboard">
                <button to="/dashboard" label="dashboard" className="bg-heading ml-1 text-white py-3 px-2 w-[160px] rounded-sm">
                  <DashboardIcon className="mr-1" />Dashboard</button>
              </Link>
            ) : (
              // <Link to={"/login"} className="">
              //   <div className="login-btn  h-full  border-black w-fit px-4 py-2 m-1  flex justify-center items-center text-xl cursor-pointer hover:bg-hover text-white border-white bg-green-500  rounded ">
              //     <img src={menuImg} alt="menu logo" className="w-6 invert mr-2"></img>
              //     login
              //   </div>
              // </Link>
              // <ElevatedButton label='Login' to="/login" iconImg={menuImg} className="w-fit h-full" buttonClassName="px-8 py-2"/>
              <Link to="/login">
                <button to="/login" label="login" className="bg-heading ml-6 text-white py-3 px-2 w-[160px] rounded-sm">
                  <AccountCircleIcon className="mr-1" />Login / Signup</button>
              </Link>
            )}

            {userId && (
              // <div
              //   onClick={() => {
              //     navigate("/profile");
              //   }}
              //   className="py-2 hover:bg-hover px-5 text-xl border-[1px] cursor-pointer rounded bg-heading"
              // >
              //   <FontAwesomeIcon className="text-white" icon={faUser} />
              // </div>
              // <ElevatedButton label={<FontAwesomeIcon  icon={faUser} />} to="/profile" buttonClassName="group py-3 hover:bg-hover px-5 text-xl border-[1px] cursor-pointer rounded " />
              <Link to="/profile">
                <button to="/profile" label="profile" className="bg-heading ml-3 text-white py-3 px-2 w-[100px] rounded-sm">
                  <AccountCircleIcon className="mr-1" />Profile</button>
              </Link>
            )}
          </div>
        </div>

        {
          <div
            id=" bg-secondary"
            className={`fixed bg-sky-100 bottom-0  ${!menuIsOpen && "hidden"
              } left-0 w-full h-[70px] shadow-inner flex items-center ${userId ? "justify-between px-6" : "justify-evenly"
              }  lg:flex lg:hidden lg:w-auto z-10`}
            style={{ zIndex: '1001' }}
          >
           <MobileNav/>
          </div>
        }
      </nav>



      {window.location.pathname === "/" && (
        <div className="hidden font-inter lg:flex h-10 text-text bg-gray-50  border w-full flex justify-evenly items-center ">
          <CityLandmarkDropDrown city='bhopal' />
          <CityLandmarkDropDrown city='indore' />
          {/* <CityLandmarkDropDrown city='pune'/> */}
          <CityLandmarkDropDrown city='chandigarh' />


          {/* <div className="group relative text-center w-22 cursor-pointer">
            <h1>Delhi <ExpandMoreIcon /></h1>
            <div className="hidden w-48 group absolute shadow rounded-lg w-max  p-4 group-hover:block z-20 bg-white">
              {city.pune.map((landmark) => {
                return (
                  <div className="w-full flex items-center justify-center text-center p-4 h-8">
                     <a href={`/search?landmark=${landmark}`}>{landmark}</a>
                  </div>
                );
              })}
              <h1 className="text-red-600">We will be there very soon...</h1>
            </div>
          </div> */}
          {/* <div className="group relative text-center w-22 cursor-pointer">
            <h1>Mumbai <ExpandMoreIcon /></h1>
            <div className="hidden w-48 group absolute shadow rounded-lg w-max  p-4 group-hover:block z-20 bg-white"> 
              {city.pune.map((landmark) => {
                return (
                  <div className="w-full flex items-center justify-center text-center p-4 h-8">
                     <a href={`/search?landmark=${landmark}`}>{landmark}</a>
                  </div>
                );
              })}
              <h1 className="text-red-600">We will be there very soon...</h1>
            </div>
          </div> */}
          {/* <div className="group relative text-center w-22 cursor-pointer">
            <h1>Bangalore <ExpandMoreIcon /></h1>
            <div className="hidden w-48 group absolute shadow rounded-lg w-max  p-4 group-hover:block z-20 bg-white">
              {city.pune.map((landmark) => {
                return (
                  <div className="w-full flex items-center justify-center text-center p-4 h-8">
                     <a href={`/search?landmark=${landmark}`}>{landmark}</a>
                  </div>
                );
              })}
              <h1 className="text-red-600">We will be there very soon...</h1>
            </div>
          </div>
        </div> */}

        </div>
      )}
    </div>
  );
}



function CityLandmarkDropDrown(props) {
  const { city } = props;
  const [isHovered, setIsHovered] = useState(false);



  const { data: landmarkList, isLoading: isLandmarkListLoading } = useQuery({
    queryKey: ['landmarkList', city],
    queryFn: async () => {

      const response = await axiosInstance.get(`/api/utils/listLandmarks/${city}`, {

        withCredentials: true
      });
      return response.data;
    },

    keepPreviousData: true,


  });

  return (
    <>
      <div
        className="group relative w-22 text-center"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <h1 className="capitalize">
          {city} {isHovered ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </h1>
        <div className="hidden capitalize absolute shadow rounded-lg w-max p-4 group-hover:block z-20 bg-white">
          <h1 className="font-semibold px-4 text-lg">Popular Landmarks</h1>
          {landmarkList && landmarkList.slice(0, 7).map((landmark) => (
            <div key={landmark.landmark_id} className="w-full block items-start font-inter justify-center py-5 text-left p-4 h-8">
              <a href={`/search?landmark=${landmark.landmark_name}`}>{landmark.landmark_name}</a>
            </div>
          ))}
          <div className="w-full block text-heading items-start font-inter justify-center text-left p-4 h-8">
            <a href={`/search?city=${city}`}>
              All landmarks{" "}
              <span>
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}