import React, { useState } from 'react';
import Slider from 'react-slick';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ScreenRotationAltOutlinedIcon from '@mui/icons-material/ScreenRotationAltOutlined';
function formatCurrency(amount) {
  const num = parseFloat(amount);

  if (isNaN(num)) {
    return "Invalid amount";
  }

  const lakh = 100000;
  const crore = 10000000;

  if (num >= crore) {
    const crores = num / crore;
    let formattedAmount = `${crores.toFixed(2)} crore`;

    return formattedAmount.trim();
  }

  if (num >= lakh) {
    const lakhs = num / lakh;
    const formattedLakhs = lakhs.toFixed(2).replace(/\.?0+$/, '');
    return `${formattedLakhs} lakh`;
  }

  return num.toLocaleString();
}

const ProjectCard = ({ project, compared, compareList, setCompareList }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };


  const [isShaking, setShaking] = useState(false)

  function handleCompareClick() {
    setShaking(true)
    if (!compared) {
      if (compareList.length <= 3) {
        setCompareList((prev) => {
          return [...prev, project.slug]
        })

      } else {
        window.alert('only select upto 4 units')
      }
    } else {
      setCompareList((prev) => {
        return [...prev].filter(value => { return value !== project.slug ? true : false })
      })
    }
    setTimeout(() => {

      setShaking(false)
    }, 2000)
  }

  return (
    <div className='flex  font-inter relative flex-wrap justify-center h-full '>
      <div className=" min-w-60  border border-heading capitalize rounded-md overflow-hidden z-10">
        <div className='overflow-hidden w-64 h-96 relative'>
          {project.images && project.images.length > 0 && (
            <div className="h-96 overflow-hidden">
              <div
                className="w-full h-full bg-cover"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_BACKEND}/${project.builder_id}/${project.project_id}/${project.images[0].imageName})`,
                }}
              ></div>
            </div>
          )}
          <div className="absolute top-0 left-0 w-full h-96 flex flex-col justify-end items-center text-primary">
            <div className='w-full  bg-gradient-to-t from-[#000000ee] to-[#00000000]'>
              <a href={`/project/${project.slug}`} className="block pt-8" style={{ textShadow: "0 0 6px black" }}>
                <h3 className="text-2xl font-semibold mb-2 text-start ml-2">{project.name}</h3>
                <div className="text-sm mb-4 ml-2">
                  <p className="text-gray-100" style={{ textShadow: "0 0 8px black" }}>
                    <LocationOnOutlinedIcon /> {project.location.city}, {project.location.landmark}
                  </p>
                 {project.price_range.min !== "0" && <p className="text-gray-100 mt-1 font-semibold" style={{ textShadow: "0 0 8px black" }}>
                    <CurrencyRupeeIcon /> {formatCurrency(project.price_range.min)} - ₹{formatCurrency(project.price_range.max)}
                  </p>}
                </div>
              </a>
            </div>
          </div>
          <a href={`/project/${project.slug}`} className='absolute top-0 left-0 w-full h-full rounded-lg mt-2 ml-2 min-w-60'></a>
          {window.location.pathname === "/hotdeals" &&
            <button onClick={handleCompareClick} style={{ animation: isShaking ? 'shake 0.8s' : "none" }} className={`absolute font-nunito top-2 left-2  ${compared ? "bg-red-400 text-primary" : " text-text bg-gray-100"}  px-3 py-2 rounded-full shadow-md z-20 hover:bg-hover transition`}>
              <ScreenRotationAltOutlinedIcon />
            </button>}
          <style>
            {`@keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-5px); }
    40% { transform: translateX(5px); }
    100% { transform: translateX(0); }
  }`}
          </style>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
