import React from 'react';
import { Box, Stack } from '@mui/material';
import { PacmanLoader } from "react-spinners";
const Loader = () =>  (
  <Box >
    <Stack direction='row' justifyContent='center' alignItems='center' >
      <PacmanLoader color="#fff" />
    </Stack>
  </Box>
);

export default Loader;