import React from 'react';

const Terms = () => {
    return (
        <div className='bg-gradient-to-r from-blue-950 to-black'>
            <main className="max-w-7xl  font-inter mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-20">
                <div className="space-y-6">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-200 ">NextOpson Terms and Conditions</h1>
                    <p className=" text-gray-300 text-sm">
                        Welcome to NextOpson! These Terms and Conditions ("Terms") govern your use of our website, mobile application, and services (collectively, the "Services"). By accessing or using the Services, you agree to be bound by these Terms.
                    </p>
                    <section>
                        <h2 className="text-2xl font-bold tracking-tight text-gray-200 ">
                            Acceptance of Terms
                        </h2>

                        <p className="list-disc mt-4  text-gray-300 text-sm">
                            By accessing or using the Services, you confirm that you accept these Terms and agree to comply with them. If you do not agree with any part of these Terms, you must not use our Services.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-2xl font-bold tracking-tight text-gray-200 ">
                            Services Provided
                        </h2>
                        <p className="text-gray-300 mt-4 text-sm">
                            To use certain features of the Services, you may need to create an account. You agree to:

                            <br /> Provide accurate, current, and complete information during the registration process.
                            <br />  Maintain the security of your password and accept all risks of unauthorized access to your account.
                            <br />  Notify us immediately if you discover or suspect any security breaches related to the Services.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-2xl font-bold tracking-tight text-gray-200 ">
                            User Responsibilities
                        </h2>
                        <p className="text-gray-300 text-sm mt-4">
                            You agree to use the Services only for lawful purposes and in accordance with these Terms. You agree not to:
                            <br />  Post any false, misleading, or fraudulent information.
                            <br />   Violate any applicable laws or regulations.
                            <br />   Infringe on the rights of others, including their intellectual property and privacy rights.
                            <br />   Distribute spam, chain letters, or other unsolicited communications.
                            <br />   Introduce any viruses, malware, or other harmful software to the Services.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-2xl font-bold tracking-tight text-gray-200 ">Property Listings</h2>
                        <p className="text-gray-300 text-sm mt-4">
                            Property listings on NextOpson are provided by third parties. We do not verify the accuracy, completeness, or legality of these listings. You acknowledge that any reliance on the listings is at your own risk.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-2xl font-bold tracking-tight text-gray-200 ">Disclaimer of Warranties</h2>
                        <p className="text-gray-300 text-sm mt-4">
                            The Services are provided on an "as is" and "as available" basis. NextOpson disclaims all warranties, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the Services will be uninterrupted, error-free, or secure.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-2xl font-bold tracking-tight text-gray-200 ">Contact Information</h2>
                        <p className="text-gray-300 text-sm mt-4">
                            If you have any questions about these Terms, please contact us at:

                            <br />   Email: theNextOpsonon@gmail.com
                            <br />  Address: Shahpura, Bhopal mp
                            <br /><br />  Thank you for using NextOpsonon!

                        </p>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default Terms;
