import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../axiosInstance";
import { Link } from "react-router-dom";

export default function RecentPage(){
    

        // recent view ------------------------------
  const {data:recentViewed, isLoading:isRecentLoading} = useQuery({queryKey:['recentViewed'], queryFn:fetchRecent})

  async function fetchRecent(){
    
    const recentResponse = await axiosInstance.post("/api/view/getRecent", { withCredential: true });
     return recentResponse.data
  }

    
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="container mx-auto p-4">
            <h2 className="px-4 pb-4 text-xl">Recently Viewed</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {!isRecentLoading && recentViewed.filter((item)=>item.price && item.contact).map((recent, index) => (
           <div className="min-w-40">
              <Link to={`/property/${recent.listing_id}`}>
           <div key={index} className="bg-primary p-4 shadow-xl border-[1px]  rounded grid place-items-center ">
               <img src={process.env.REACT_APP_BACKEND + recent.thumbnail} className="w-40 h-40 object-contain" alt={recent.listing_name} />
                <h1 className="text-lg font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[8rem]">{recent.listing_name}</h1>
              <p className="text-sm">{new Intl.NumberFormat('en-IN', { style: "currency", currency: "INR", maximumFractionDigits: '0' }).format(recent.price)}</p>
            </div>
                </Link>
            <a href={`tel:${recent.contact}`} className="bg-green-500 rounded-b text-white p-2 grid  place-items-center">
              <h1>call </h1>
              <p className="text-sm">+91-{recent.contact}</p>
            </a>
            </div>
          ))}
          </div>
        </div>
      </div>
    );
}