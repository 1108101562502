import React, { Suspense } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import theme from "./theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./components/header";
import Loader from "./components/loader";
import Footer from "./components/footer";
import MyData from "./components/myData";
import Overview from "./components/overview";
import ProjectListing from "./components/project/projectListing";
import SetSeller from "./components/setSeller";
import SolarData from "./components/others/solarData.jsx";
import HotDealsPage from "./components/hotDeals/hotDealsPage.jsx";
import FaqAccordion from "./components/pages/faqAccordion.jsx"
import Terms from "./components/pages/Term.jsx"
import AboutPage from "./components/pages/AboutPage.jsx"
import PrivacyPage from "./components/pages/Privacy.jsx";
import Contact from "./components/pages/ContactUs.jsx";
import Team from "./components/pages/Team.jsx"
import Pricing from "./components/pages/Pricing.jsx";
import RecentPage from "./components/recentPage.jsx";

const Home = React.lazy(() => import("./components/home"));
const Authenticate = React.lazy(() =>
  import("./components/authenticate/authenticate")
);
const Forget = React.lazy(() => import("./components/authenticate/forget"));
const NewListing = React.lazy(() => import("./components/newListing"));
const UpdateListing = React.lazy(() => import("./components/updateListing"));
const PrivateRoutes = React.lazy(() =>
  import("./components/authenticate/privateRoute")
);
const Search = React.lazy(() => import("./components/search"));
const PropertyDetails = React.lazy(() => import("./components/propertyPage"));
const ProfilePage = React.lazy(() => import("./components/profilePage"));
const NotFound = React.lazy(() => import("./components/notFound"));
const Dashboard = React.lazy(() => import("./components/dashboard"));
const Project = React.lazy(() => import("./components/project/project"));
const About = React.lazy(() => import("./components/aboutPage.jsx"));
const ProjectForm = React.lazy(() =>
  import("./components/project/projectForm/projectForm")
);

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "login",
          element: <Authenticate />,
        },
        {
          path: "forget",
          element: <Forget />,
        },
        {
          path: "search",
          element: <Search />,
        },
        {
          path: "property/:id",
          element: <PropertyDetails />,
          scrollRestoration:'manual'
        },
        {
          path: "project/:id",
          element: <Project />,
        },
        {
          path: "dashboard",
          element: <Dashboard />,
          children: [
            {
              path: "upload",
              element: <NewListing />,
            },
            {
              path: "edit/:id",
              element: <UpdateListing />,
            },
            {
              path: "addProject",
              element: <ProjectForm />,
            },
            {
              index:true,
              
              element: <Overview />,
            },
            { path: "active", element: <MyData key='active' pageType="active" /> },
            { path: "myData", element: <MyData key="mydata" pageType="all" /> },
            { path: "sold", element: <MyData key='sold' pageType="sold" /> },
            { path: "projects", element: <ProjectListing /> },
            { path: "PostListing", element: <SetSeller /> },
            { path: "recentViews", element: <RecentPage /> },
          ],
        },
        {
          path: "profile",
          element: <ProfilePage />,
        },
        {
          path:"Vindhya-solars",
          element:<SolarData/>
        },
        {
          path:"hotDeals",
          element:<HotDealsPage/>
        }
        ,
        // {
        //   path: "about",
        //   element: <AboutPage />,
        // },
        {
          path: "faqAccordion",
          element: <FaqAccordion />,
        },
        {
          path: "terms",
          element: <Terms />,
        },
        {
          path: "privacy",
          element: <PrivacyPage />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "ourteam",
          element: <Team />,
        },
        {
          path: "pricing",
          element: <Pricing />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ],
  {
    scrollRestoration: "auto", // Set the default behavior h
  }
);

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loader />}>
          <RouterProvider router={router} />
        </Suspense>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
