import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqAccordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const faqs = [
        {
            question: "What is Nextopson and how does it work?",
            answer: "Nextopson is an online marketplace zero brokerage real estate platform. Here at Nextopson we are connecting buyer/tenant to seller directly without any hassle."
        },
        {
            question: "Do i have to pay for something?",
            answer: "No, Nextopson is 100% free for tenant/buyer."
        },
        {
            question: "How can I get the benefit as a property owner?",
            answer: "Upload your property in simple steps and quickly get buyer/tenant."
        },
        {
            question: "What types of properties can be listed on Nextopson?",
            answer: "Nextopson allows listings for a wide range of properties, including residential homes, apartments, commercial spaces, plots and land."
        },
        {
            question: "Does Nextopson provide any customer support?",
            answer: "Yes, we offer customer support via phone call and email. Our support team is available to assist you with any questions or issues you may have."
        },
        {
            question: "Can I list properties that are available for both sale and rent?",
            answer: "Yes, you can specify if your property is available for sale, rent, or both when you create your listing."
        }
    ];
    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-gradient-to-r from-blue-950 to-black">
            <h1 className="text-3xl text-blue-200 font-bold mb-8 text-center">FAQ</h1>
            {faqs.map((faq, index) => (
                <div key={index} className=" rounded-xl bg-transparent border border-gray-700 shadow-md mb-4">
                    <div
                        className="flex items-center text-blue-200 justify-between px-6 py-4 cursor-pointer "
                        onClick={() => toggleFAQ(index)}
                    >
                        <h2 className="text-lg  font-semibold">{faq.question}</h2>
                        <ExpandMoreIcon />
                    </div>
                    {activeIndex === index && (
                        <div className="px-6 py-4 text-blue-200">
                            <p>{faq.answer}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
export default FaqAccordion;
