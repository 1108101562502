import React from "react";
import { CiLogin, CiSearch, CiUser } from "react-icons/ci";
import { GoHome } from "react-icons/go";
import { LuLayoutDashboard } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { MdOutlineSort } from "react-icons/md";
import { IoFilter } from "react-icons/io5";
const MobileNav = () => {
  const navigate = useNavigate();
  function scrolltoTop(path) {
    if (window.location.href !== path) {
      navigate(path);
    }

    if (window.scrollY > window.innerHeight) {
      window.scrollTo(0, 0);
    }
  }

  const userId = localStorage.getItem("id");
  return (
    <>
      {window.location.pathname === "/search" ? (
        ""
      ) : (
        <>
          <div
            onClick={() => {
              scrolltoTop("/");
            }}
            className={` border-black ${
              window.location.pathname === "/" && "bg-sky-200"
            } min-w-16 h-14  rounded-2xl p-2 flex flex-col justify-center items-center text-2xl text-gray-600`}
          >
            <GoHome
              className={` h-20 ${
                window.location.pathname === "/" && "text-indigo-600"
              }`}
            />
            <h1
              className={` ${
                window.location.pathname === "/"
                  ? "text-indigo-600"
                  : "text-gray-700"
              } text-sm`}
            >
              Home
            </h1>
          </div>

          <div
            onClick={() => {
              scrolltoTop("/search?sort=relevance");
            }}
            className={`login-btn  border-black ${
              window.location.pathname === "/search" && "bg-sky-200"
            } min-w-16 h-14 rounded-2xl p-2 flex flex-col justify-center items-center text-2xl text-gray-600 `}
          >
            <CiSearch
              className={`w-7 ${
                window.location.pathname === "/search" && "text-indigo-600"
              }`}
            />
            <h1
              className={` ${
                window.location.pathname === "/search"
                  ? "text-indigo-600"
                  : "text-gray-700"
              } text-sm`}
            >
              Search
            </h1>
          </div>
          {userId && (
            <>
              <div
                onClick={() => {
                  navigate("/dashboard");
                }}
                className={`dashboard ${
                  window.location.pathname === "/dashboard" && "bg-sky-200"
                } min-w-16 h-14 rounded-2xl p-2 border-black  flex flex-col justify-center items-center text-2xl text-gray-600 `}
              >
                <LuLayoutDashboard
                  className={`w-7 ${
                    window.location.pathname === "/dashboard" &&
                    "text-indigo-600"
                  }`}
                />
                <h1
                  className={` ${
                    window.location.pathname === "/dashboard"
                      ? "text-indigo-600"
                      : "text-gray-700"
                  } text-sm`}
                >
                  Dashboard
                </h1>
              </div>{" "}
            </>
          )}
          <div
            onClick={() => {
              !userId ? navigate("/login") : navigate("/profile");
            }}
            className={`login-btn ${
              (window.location.pathname === "/profile" ||
                window.location.pathname === "/login") &&
              "bg-sky-200"
            } min-w-16 h-14 rounded-2xl p-2 border-black flex flex-col justify-center  items-center text-2xl text-gray-600  `}
          >
            {!userId ? (
              <>
                {" "}
                <CiLogin
                  className={`w-7 ${
                    window.location.pathname === "/login" && " text-indigo-600"
                  }`}
                />
                <h1
                  className={` ${
                    window.location.pathname === "/login"
                      ? "text-indigo-600"
                      : "text-gray-700"
                  } text-sm`}
                >
                  Login
                </h1>
              </>
            ) : (
              <>
                <CiUser
                  className={`w-7 ${
                    window.location.pathname === "/profile" && "text-indigo-600"
                  }`}
                />
                <h1
                  className={` ${
                    window.location.pathname === "/profile"
                      ? "text-indigo-600"
                      : "text-gray-700"
                  } text-sm`}
                >
                  Profile
                </h1>{" "}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MobileNav;
