import Slider from "react-slick";

export default function ProjectCard2(props){
    const project = props.project;

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
      };

      function urlGen(url){
        return `${process.env.REACT_APP_BACKEND}/${project.builder_id}/${project.project_id}/${url}`
      }
    return(
        <div className="bg-white shadow-md rounded-lg capitalize font-nunito overflow-hidden md:flex">
            <div className="relative h-full md:w-1/2">
            

              {project.images && (
                <Slider {...sliderSettings}>
                  {project.images &&
                    project.images.map((image, index) => (
                      <div  key={index}  className={`slider-item h-[400px] bg-[url(${urlGen(image.imageName)})]`}  
                       
                     >
                        <img
                          src={
                            process.env.REACT_APP_BACKEND +
                            `/${project.builder_id}/${project.project_id}/${image.imageName}`
                          }
                          alt={image.imageName}
                          className="w-full h-[400px] md:h-full object-contain"
                        />
                      </div>
                    ))}
                </Slider>
               
               )}
              
              <div className="absolute top-2 left-2 bg-gray-800 text-white px-2 py-1 rounded">
                {project.status}
              </div>
            </div>
            <div className="p-4 md:w-1/2 md:p-6">
              <h2 className="text-xl font-bold mb-2"><a href={`/project/${project.slug}`} target="_blank" >{project.name}</a></h2>
              <p className="text-gray-600 mb-4">{project.description}</p>
              <div className="grid grid-cols-2 gap-4 mb-4">
               
                <div>
                  <p className="text-gray-600 mb-1">Project ID</p>
                  <p className="font-bold">{project.project_id}</p>
                </div>
                <div>
                  <p className="text-gray-600 mb-1">Start Date</p>
                  <p className="font-bold">
                    {new Date(project.start_date).toLocaleDateString()}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 mb-1">Completion Date</p>
                  <p className="font-bold">
                    {new Date(project.completion_date).toLocaleDateString()}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 mb-1">Total Units</p>
                  <p className="font-bold">{project.total_units}</p>
                </div>
                <div>
                  <p className="text-gray-600 mb-1">RERA ID</p>
                  <p className="font-bold">{project.rera_id}</p>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-600 mb-1">Location</p>
                  <p className="font-bold">{`${project.location.city}, ${project.location.state}`}</p>
                </div>
                <a
                  href={project.brochure_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  View Brochure
                </a>
              </div>
            </div>
          </div>
    )
}