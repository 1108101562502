import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import locationicon from "../../assets/location-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import "leaflet/dist/leaflet.css";

import axios from "axios";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41],
});

const locationIcon = L.icon({
  iconUrl: locationicon,

  iconAnchor: [12, 24],
});

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

const Location = (props) => {
  const propertyLocation = props.data;

  console.log(propertyLocation);

  const [nearbyPOIs, setNearbyPOIs] = useState([]);

  //   const fetchNearbyPOIs = async (category) => {
  //     try {
  //       const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&limit=10&q=${category}&lat=${propertyLocation.coordinates.latitude}&lon=${propertyLocation.coordinates.longitude}`);
  //       setNearbyPOIs(response.data);
  //       console.log(response.data)
  //     } catch (error) {
  //       console.error('Error fetching nearby POIs:', error);
  //     }
  //   };

  const fetchNearbyPOIs = async (category) => {
    try {
      const { city, state, landmark } = propertyLocation;
      console.log(propertyLocation);
      const { latitude: homeLatitude, longitude: homeLongitude } =
        propertyLocation.coordinates;

      // Perform geocoding to find nearby POIs
      const geocodeResponse = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&limit=10&q=${category} in ${landmark} ${city}`
      );
      const nearbyPOIs = geocodeResponse.data;

      const poisWithDistance = nearbyPOIs.map((poi) => {
        const poiLatitude = parseFloat(poi.lat);
        const poiLongitude = parseFloat(poi.lon);
        const distance = calculateDistance(
          homeLatitude,
          homeLongitude,
          poiLatitude,
          poiLongitude
        );
        return { ...poi, distance };
      });

      poisWithDistance.sort((a, b) => a.distance - b.distance);

      // Set the state with POIs and their distances
      setNearbyPOIs(poisWithDistance);
      console.log(poisWithDistance);
    } catch (error) {
      console.error("Error finding nearby POIs:", error);
      return null;
    }
  };

  return (
    <div className="bg-primary rounded-lg  pt-6">
 
      {/* Buttons to fetch nearby POIs */}

      {/* Map */}
      <div className="h-64">
        <MapContainer
          center={[
            parseFloat(propertyLocation.coordinates.latitude),
            parseFloat(propertyLocation.coordinates.longitude),
          ]}
          zoom={13}
          scrollWheelZoom={false}
          className="h-full"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* Display property marker */}
          <Marker
            position={[
              propertyLocation.coordinates.latitude,
              propertyLocation.coordinates.longitude,
            ]}
            icon={DefaultIcon}
          >
            <Popup>
              <div>
                <p>{propertyLocation.address}</p>
                <p>{propertyLocation.landmark}</p>
                <p>
                  {propertyLocation.city}, {propertyLocation.state}
                </p>
              </div>
            </Popup>
          </Marker>
          {/* Display nearby POI markers */}
          {nearbyPOIs.map((poi, index) => (
            <Marker
              key={index}
              position={[parseFloat(poi.lat), parseFloat(poi.lon)]}
              icon={locationIcon}
            >
              <Popup>{poi.display_name}</Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
      <div className="p-4">
        {/* Display location details */}
        <div className="mb-4">
          <p className="text-gray-500">Address:</p>
          <p className="">{propertyLocation.address}</p>
        </div>
       
      </div>
     
      <style jsx>
        {`
          .map-buttons::-webkit-scrollbar {
            width: 10px;
            border-radius: 10px;
            height: 8px;
          }

          /* Track */
          .map-buttons::-webkit-scrollbar-track {
            background: #ffffff;
            height: 5px;
          }

          /* Handle */
          .map-buttons::-webkit-scrollbar-thumb {
            background: #88888877;
            height: 8px;
            border-radius: 20px;
            opacity: 0.4;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            opacity: 0.7;
            background: #dddddd;
          }
        `}
      </style>

      {nearbyPOIs.length > 0 ? (
        <div>
          <h2>Nearby Points of Interest</h2>
          <TableContainer
            className="max-h-[400px] overflow-auto"
            component={Paper}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Place</TableCell>
                  <TableCell>Distance (km)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                style={{ overflowY: "auto", maxHeight: "400px" }}
                sx={{ overflowY: "auto", maxHeight: "400px" }}
              >
                {nearbyPOIs.map((poi) => (
                  <TableRow
                    key={poi.place_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{poi.name || poi.display_name}</TableCell>
                    <TableCell>
                      {poi.distance < 1
                        ? (poi.distance * 1000).toFixed(0) + " m"
                        : poi.distance.toFixed(2) + " km"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Location;
