import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const ContactPage = () => {
    const position = [23.211, 77.434];
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const serviceId = 'service_tg7dr5n';
        const templateId = 'template_qn8r6ee';
        const autoReplyTemplateId = 'template_wptj86i';
        const userId = 'WGS_cK8uG1uqT-HL_';

        emailjs.send(serviceId, templateId, formData, userId)
            .then((response) => {
                const autoReplyParams = {
                    name: formData.name,
                    email: formData.email
                };

                emailjs.send(serviceId, autoReplyTemplateId, autoReplyParams, userId)
                    .then((response) => {
                        // console.log("auto reply send");
                    })
                    .catch((error) => {
                       console.log(error);
                    });

                toast.success('Email sent successfully!');
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });
            })
            .catch((error) => {
                toast.error('Failed to send email. Please try again later.');
            });
    };

    return (
        <div className="bg-gradient-to-r font-inter from-blue-950 to-black min-h-screen flex items-center justify-center p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-6xl">
                <div className="p-4 rounded-lg shadow-lg bg-opacity-75 bg-transparent border border-gray-600 flex flex-col items-center">
                    <h1 className="text-2xl font-bold mb-2 text-blue-200">Our Location</h1>
                    <div className="w-full h-64 md:h-full">
                        <MapContainer center={position} zoom={13} className="h-full w-full rounded-lg">
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={position}>
                                <Popup>
                                    C21 Indus Apartment, Shahpura, Bhopal
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>
                <div className="p-8 rounded-lg shadow-lg bg-opacity-75 bg-transparent border border-gray-600">
                    <h2 className="text-3xl font-semibold mb-6 text-gray-200 text-center">Get in Touch</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="name" className="block text-gray-200">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full p-2 mt-1 rounded-lg bg-transparent border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-gray-200">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full p-2 mt-1 rounded-lg bg-transparent border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="message" className="block text-gray-200">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                className="w-full p-2 mt-1 rounded-lg bg-transparent border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                        </div>
                        <div className="text-center">
                            <button type="submit" className="bg-heading text-white py-2 px-6 rounded-lg transition transform hover:bg-sky-600 hover:-translate-y-1">
                                Send
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ContactPage;

